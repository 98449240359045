/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2022 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Lightning, Storage, Router, Utils, Profile, Language, Registry } from "@lightningjs/sdk";
import routes from "./lib/routes";
import { Menu } from "./widgets/Menu/Menu";
import { DEVICE_DIMENSIONS, isExternalPlatform, STORAGE_KEYS } from "./lib/utils";
import { Loading } from "./components/Loading/Loading";
import { PageBackground } from "./components/PageBackground/PageBackground";
import { Auth } from "./widgets/Auth/Auth";
import { Subscription } from "./widgets/Subscription/Subscription";
import { Privacy } from "./widgets/Privacy/Privacy";
import { Terms } from "./widgets/Terms/Terms";
import { Support } from "./widgets/Support/Support";
import { Exit } from "./widgets/Exit/Exit";
import { IAPSubscription } from "./widgets/IAPSubscription/IAPSubscription";

interface AppTemplateSpec extends Lightning.Component.TemplateSpecLoose {
    Background: typeof PageBackground;
    Pages: object;
    Loading: {
        Label: object;
        Loader: typeof Loading;
    };
    Widgets: {
        Menu: typeof Menu;
        Auth: typeof Auth;
        Subscription: typeof Subscription;
        Privacy: typeof Privacy;
        Terms: typeof Terms;
        Support: typeof Support;
        Exit: typeof Exit;
        IAPSubscription: typeof IAPSubscription;
    };
}

export class App
    extends Router.App<AppTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<AppTemplateSpec>
{
    /*
     * The following properties exist to make it more convenient to access elements
     * below in a type-safe way. They are optional.
     *
     * See https://lightningjs.io/docs/#/lightning-core-reference/TypeScript/Components/TemplateSpecs?id=using-a-template-spec
     * for more information.
     */

    _zeasnKeys: any = {};
    _zeasnKeyTriggered = false;
    _lastKeyPressedAt = 0;

    _boundEventHandlers: any = {};

    readonly Exit = this.getByRef("Widgets")!.getByRef("Exit")!;

    override _setup() {
        Router.startRouter(routes);

        this._boundEventHandlers = {
            _handleAppClose: this._handleAppClose.bind(this)
        };
    }

    static override _template(): Lightning.Component.Template<AppTemplateSpec> {
        return {
            w: DEVICE_DIMENSIONS.w,
            h: DEVICE_DIMENSIONS.h,
            collision: true,
            Background: { type: PageBackground },
            Pages: { forceZIndexContext: true, collision: true },
            Loading: {
                Loader: { type: Loading, isMain: true }
            },
            Widgets: {
                Menu: {
                    type: Menu
                },
                Auth: {
                    type: Auth
                },
                Subscription: {
                    type: Subscription
                },
                Privacy: {
                    type: Privacy
                },
                Terms: {
                    type: Terms
                },
                Support: {
                    type: Support
                },
                Exit: {
                    type: Exit
                },
                IAPSubscription: {
                    type: IAPSubscription
                }
            }
        };
    }

    static colors() {
        return {
            background: "#000000",
            primary: "#00A5D6",
            focus: "#FFFFFF",
            text: "#FFFFFF"
        };
    }

    static language() {
        return {
            file: Utils.asset("lang.json"),
            language: this._getLanguage()
        };
    }

    static getFonts() {
        return [
            {
                family: "Regular",
                url: Utils.asset("fonts/AvenirNextLTPro-Regular.ttf") as string
            },
            {
                family: "Medium",
                url: Utils.asset("fonts/AvenirNextLTPro-Medium.ttf") as string
            },
            {
                family: "SemiBold",
                url: Utils.asset("fonts/AvenirNextLTPro-Demi.ttf") as string
            },
            {
                family: "Bold",
                url: Utils.asset("fonts/AvenirNextLTPro-Bold.ttf") as string
            },
            {
                family: "Heavy",
                url: Utils.asset("fonts/AvenirNextLTPro-Heavy.ttf") as string
            },
            {
                family: "Secondary-Regular",
                url: Utils.asset("fonts/ProximaNova-Regular.ttf") as string
            },
            {
                family: "Secondary-SemiBold",
                url: Utils.asset("fonts/ProximaNova-Semibold.ttf") as string
            }
        ];
    }

    override _init() {
        if (typeof window.VK_PLAY !== "undefined") {
            this._zeasnKeys = {
                VK_PLAY: {
                    key: window.VK_PLAY,
                    value: 415
                },
                VK_STOP: {
                    key: window.VK_STOP,
                    value: 413
                },
                VK_PAUSE: {
                    key: window.VK_PAUSE,
                    value: 19
                },
                VK_PLAY_PAUSE: {
                    key: window.VK_PLAY_PAUSE,
                    value: 503
                },
                VK_BACK: {
                    key: window.VK_BACK,
                    value: 461
                },
                VK_BACK_SPACE: {
                    key: window.VK_BACK_SPACE,
                    value: 461
                },
                VK_FAST_FWD: {
                    key: window.VK_FAST_FWD,
                    value: 417
                },
                VK_REWIND: {
                    key: window.VK_REWIND,
                    value: 412
                },
                VK_LEFT: { key: window.VK_LEFT, value: 37 },
                VK_RIGHT: { key: window.VK_RIGHT, value: 39 }
            };
        }

        if (isExternalPlatform()) {
            Registry.addEventListener(window, "handleAppExit", this._boundEventHandlers._handleAppClose);
        }
    }

    override _captureKey(e: KeyboardEvent) {
        e.preventDefault();

        // for LG !!
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const keyCode = e.keyCode || e.code || e.keyIdentifier;

        console.log("keyCode", keyCode);

        if (!e.keyCode && e.keyCode !== 0 && keyCode) {
            window.dispatchEvent(new KeyboardEvent("keydown", { ...e, keyCode: keyCode }));
            return true;
        }

        const zeasnKey = Object.keys(this._zeasnKeys).find((key) => this._zeasnKeys[key].key === keyCode);
        if (zeasnKey && !this._zeasnKeyTriggered) {
            this._zeasnKeyTriggered = true;
            window.dispatchEvent(new KeyboardEvent("keydown", { ...e, keyCode: this._zeasnKeys[zeasnKey].value }));
            return true;
        }
        this._zeasnKeyTriggered = false;

        const activeHash = Router.getActiveHash();

        if (activeHash === "playback") return false;

        const delay = e.repeat ? 350 : 150;
        const now = Date.now();

        if (now - this._lastKeyPressedAt < delay) {
            return true;
        }
        this._lastKeyPressedAt = now;

        return Router.isNavigating();
    }

    override _handleLeft() {
        Router.focusWidget("Menu");
    }

    private static _getLanguage() {
        return new Promise((resolve) => {
            (async () => {
                const savedLang = Storage.get(STORAGE_KEYS.selectedLanguage);

                if (savedLang) {
                    resolve(savedLang);
                    return;
                }

                const profileLang = await Profile.language();
                if (Language.available().find((lang: { code: string }) => lang.code === profileLang)) {
                    Storage.set(STORAGE_KEYS.selectedLanguage, profileLang);
                    resolve(profileLang);
                    return;
                }

                Storage.set(STORAGE_KEYS.selectedLanguage, "en");
                resolve("en");
            })();
        });
    }

    override _handleAppClose() {
        console.log("_handleAppClose");
        this._toggleExitDialog().then((confirmed) => {
            console.log("close confirmed " + confirmed);
            // close the application
            if (confirmed) {
                try {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.application.closeApp();
                } catch (e) {
                    console.log(e);
                }

                try {
                    console.log(
                        "isExternalPlatform() && window.Android?.exitApp " + isExternalPlatform() &&
                            window.Android?.exitApp
                    );
                    if (isExternalPlatform() && window.Android?.exitApp) {
                        window.Android.exitApp();
                    }
                } catch (e) {
                    console.log(e);
                }

                try {
                    if (typeof window.SmartTvA_API !== "undefined") {
                        window.SmartTvA_API.exit();
                    }

                    window.history.go(-999);

                    window.close();
                } catch (e) {
                    console.log("e", e);
                }
            }
        });
    }

    _toggleExitDialog() {
        return new Promise((resolve): void => {
            this.Exit.open(resolve);
            Router.focusWidget("Exit");
        });
    }
}
