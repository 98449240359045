// to T&C/Privacy Policy of Stingray Webpage

import { Lightning, Router } from "@lightningjs/sdk";
import { SettingsItem } from "./SettingsItem";

interface SettingsPrivacyTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItem;
}

export class SettingsPrivacy
    extends Lightning.Component<SettingsPrivacyTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsPrivacyTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsPrivacyTemplateSpec> {
        return {
            Layout: {
                type: SettingsItem,
                title: "settingPrivacy",
                selectedOptionKey: "legal.stingray.com"
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _handleEnter() {
        Router.focusWidget("Privacy");
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }
}
