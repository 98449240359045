import { Lightning } from "@lightningjs/sdk";
import { CategoryList } from "../components/CategoryList/CategoryList";
import { DIMENSIONS, PAGES } from "../lib/utils";
import { MovieModel } from "../lib/models";

interface HomeTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof CategoryList;
}

interface HomeTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Home
    extends Lightning.Component<HomeTemplateSpec, HomeTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<HomeTemplateSpec>
{
    readonly List = this.getByRef("List")!;

    static override _template(): Lightning.Component.Template<HomeTemplateSpec> {
        return {
            collision: true,
            List: {
                type: CategoryList,
                x: DIMENSIONS.menu,
                collision: true
            }
        };
    }

    set data(value: MovieModel[]) {
        this.application.emit("clearBackground");

        this.List.addItems([
            {
                categoryId: 0,
                movies: value
            }
        ]);
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this.List;
    }

    override _active() {
        this.application.emit("setActivePage", PAGES.home.name);
    }

    override _inactive() {
        this.stage.gc(true);
    }
}
