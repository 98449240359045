import { Lightning } from "@lightningjs/sdk";
import { Badge } from "../Badge/Badge";
import { SeparatedText } from "../SeparatedText/SeparatedText";
import { MovieName } from "../MovieName/MovieName";
import { formatTime, getInfoFromTitle } from "../../lib/utils";
import { MovieDetailsModel, MovieModel } from "../../lib/models";

interface MovieDetailsTemplateSpec extends Lightning.Component.TemplateSpec {
    maxW: number;
    data: MovieDetailsModel;
    Name: typeof MovieName;
    Labels: {
        Badge: typeof Badge;
        Year: typeof SeparatedText;
        Age: typeof SeparatedText;
        Time: object;
        Separator: typeof SeparatedText;
    };
}

export class MovieDetails
    extends Lightning.Component<MovieDetailsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieDetailsTemplateSpec>
{
    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<MovieDetailsTemplateSpec> {
        return {
            flex: { direction: "column" },
            Name: {
                maxW: 1400,
                type: MovieName
            },
            Labels: {
                alpha: 0,
                flex: { direction: "row" },
                Badge: {
                    type: Badge,
                    label: "",
                    w: 80,
                    flexItem: { marginRight: 32 }
                },
                Year: {
                    type: SeparatedText,
                    label: "",
                    fontSize: 24
                },
                Time: {
                    text: {
                        text: "",
                        fontSize: 24,
                        fontFace: "Secondary-Regular"
                    }
                }
            }
        };
    }

    readonly Name = this.getByRef("Name")!;
    readonly Labels = this.getByRef("Labels")!;
    readonly Time = this.Labels.getByRef("Time")!;
    readonly Badge = this.Labels.getByRef("Badge")!;
    readonly Year = this.Labels.getByRef("Year")!;

    override _setup() {
        this._boundEventHandler = this._update.bind(this);
    }

    override _active() {
        this.application.on("updateSelectedMovie", this._boundEventHandler);
    }

    override _inactive() {
        this.application.off("updateSelectedMovie", this._boundEventHandler);
    }

    _update(data: MovieModel) {
        if (data.isHd) {
            this.Badge.patch({
                label: "FHD"
            });
        } else if (data.is4k) {
            this.Badge.patch({
                label: "4K"
            });
        }

        this.Time.patch({
            text: {
                text: formatTime(data.duration * 60, {
                    useSeconds: false,
                    hideEmpty: true
                })
            }
        });

        this.Year.patch({
            label: data.productionYear?.toString() || ""
        });

        this.Name.patch({ text: getInfoFromTitle(data?.title).title });

        this.Labels.patch({
            alpha: 1
        });
    }

    set maxW(value: number) {
        this.Name.patch({
            maxW: value
        });
    }

    set data(value: MovieDetailsModel) {
        this._update(value);
    }
}
