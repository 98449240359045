import { Colors, Lightning } from "@lightningjs/sdk";

// @ts-expect-error no types available yet
import { ProgressBar } from "@lightningjs/ui";

interface PlayerProgressTemplateSpec extends Lightning.Component.TemplateSpec {
    progress: number;

    Progress: {
        ProgressPoint: object;
        ProgressBar: typeof ProgressBar;
    };
}

export class PlayerProgress
    extends Lightning.Component<PlayerProgressTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayerProgressTemplateSpec>
{
    readonly Progress = this.getByRef("Progress")!;
    readonly ProgressPoint = this.Progress.getByRef("ProgressPoint")!;
    readonly ProgressBar = this.Progress.getByRef("ProgressBar")!;

    static override _template(): Lightning.Component.Template<PlayerProgressTemplateSpec> {
        return {
            Progress: {
                h: 6,
                w: 1520,
                ProgressBar: {
                    type: ProgressBar,
                    h: 6,
                    w: 1520,
                    value: 0,
                    progressColor: Colors("primary").get(),
                    backgroundColor: Colors("white").get()
                },
                ProgressPoint: {
                    w: 24,
                    h: 24,
                    mountY: 0.5,
                    y: (h) => h / 2,
                    x: 0,
                    texture: Lightning.Tools.getRoundRect(
                        24,
                        24,
                        12,
                        8,
                        Colors("primary").get(),
                        true,
                        Colors("white").get()
                    )
                }
            }
        };
    }

    // between 0, 1
    set progress(value: number) {
        this.ProgressBar.patch({
            value: value
        });

        const percent = Math.min(100, Math.max(0, value * 100));
        this.ProgressPoint.patch({
            x: ((1520 - 14) / 100) * percent - 1
        });
    }
}
