// to T&C/Privacy Policy of Stingray Webpage

import { Lightning, Router } from "@lightningjs/sdk";
import { SettingsItem } from "./SettingsItem";

interface SettingsTermsTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItem;
}

export class SettingsTerms
    extends Lightning.Component<SettingsTermsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsTermsTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsTermsTemplateSpec> {
        return {
            Layout: {
                type: SettingsItem,
                title: "settingTerms",
                selectedOptionKey: "www.stingray.com"
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _handleEnter() {
        Router.focusWidget("Terms");
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }
}
