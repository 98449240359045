import { Lightning } from "@lightningjs/sdk";

// @ts-expect-error no types available yet
import { Grid } from "@lightningjs/ui";

import { SettingsSubscription } from "./SettingsSubscription";
import { SettingsPrivacy } from "./SettingsPrivacy";
import { SettingsTerms } from "./SettingsTerms";
import { SettingsSupport } from "./SettingsSupport";
import { SettingsItemContainer } from "../../pages/Settings";

interface SettingsInfoListTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof Grid;
}

interface SettingsInfoListTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class SettingsInfoList
    extends Lightning.Component<SettingsInfoListTemplateSpec, SettingsInfoListTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SettingsInfoListTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingsInfoListTemplateSpec> {
        return {
            collision: true,
            w: (w) => w,
            List: {
                collision: true,
                h: 220,
                w: (w: number) => w,
                x: 0,
                type: Grid,
                spacing: 64,
                columns: 2,
                items: [
                    { type: SettingsItemContainer, content: SettingsSubscription },
                    { type: SettingsItemContainer, content: SettingsPrivacy },
                    { type: SettingsItemContainer, content: SettingsSupport },
                    { type: SettingsItemContainer, content: SettingsTerms }
                ]
            }
        };
    }

    readonly List = this.getByRef("List")!;

    override _getFocused() {
        return this.List;
    }

    override _setup() {
        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    $listItemHover(e: any) {
        const index = this.List.items.findIndex((item: any) => item === e.parent);

        this.List.setIndex(index);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.signal("handleHover");

        return true;
    }
}
