import { CategoryModel, PlaybackModel, MovieModel, UserModel, MovieDetailsModel, GoogleIAPData } from "./models";
import camelcaseKeys from "./camelKeys";
import { Language, Profile, Storage } from "@lightningjs/sdk";
import { v4 as uuidv4 } from "uuid";
import { STORAGE_KEYS } from "./utils";

export const API_URL = process.env.APP_API_URL || "https://qello.alteox.app/api";

export const getLang = () => {
    return Language.get() || "en";
};

export const getPlatform = async () => {
    return Storage.get(STORAGE_KEYS.platform) || (await Profile.platform());
};

export const deviceToken = async () => {
    let uid = Storage.get(STORAGE_KEYS.uid);

    if (uid) return uid;

    uid = await Profile.uid();

    // local browser
    if (uid === "ee6723b8-7ab3-462c-8d93-dbf61227998e") {
        uid = uuidv4();
    }

    Storage.set(STORAGE_KEYS.uid, uid);

    return uid;
};

export const getUserId = () => {
    return Storage.get(STORAGE_KEYS.user)?.userId || "";
};

interface RequestInitExtended extends RequestInit {
    params?: undefined | string | string[][] | Record<string, any> | URLSearchParams;
}
function api<T>(url: string, options?: RequestInitExtended | undefined): Promise<T> {
    if (options?.params) {
        url += `?${new URLSearchParams(options.params).toString()}`;
    }
    console.log(`FETCH: ${url}`);

    return fetch(url, options)
        .then((response) => {
            console.log(`FETCH RES: ${response.status}`);
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return response.json<T>();
        })
        .then((data) => {
            return camelcaseKeys(data);
        })
        .catch((error: Error) => {
            console.log("FETCH ERR");
            console.log(error);
            throw error; /* <-- rethrow the error so consumer can still catch it */
        });
}

const normalizeMovies = (data: MovieDetailsModel[]): MovieModel[] => {
    return data.map((m: MovieDetailsModel) => ({
        backdropUrl: m.backdropUrl,
        title: m.title,
        duration: m.duration,
        productionYear: m.productionYear,
        movieId: m.movieId,
        isFavorite: m.isFavorite,
        progress: m.progress,
        coverUrl: m.coverUrl,
        isHd: m.isHd,
        is4k: m.is4k
    }));
};

export const getNewReleases = async (): Promise<MovieModel[]> => {
    return normalizeMovies(
        await api(`${API_URL}/movies`, {
            params: {
                category: "new-releases",
                lang: getLang(),
                token: await deviceToken()
            },
            cache: "force-cache",
            headers: {
                "Cache-Control": "max-age=1500"
            }
        })
    );
};

export const getCategories = async (): Promise<CategoryModel[]> => {
    return await api(`${API_URL}/categories`, {
        params: {
            lang: getLang()
        }
    });
};

export const getMoviesByCategory = async (categoryId: number): Promise<MovieModel[]> => {
    return normalizeMovies(
        await api(`${API_URL}/movies`, {
            params: {
                category: categoryId.toString(),
                lang: getLang(),
                token: await deviceToken()
            },
            cache: "force-cache",
            headers: {
                "Cache-Control": "max-age=1500"
            }
        })
    );
};

export const getMovieById = async (
    movieId: number
): Promise<{ movie: MovieDetailsModel; progress: number; isFavorite: boolean }> => {
    return await api<{ movie: MovieDetailsModel; progress: number; isFavorite: boolean }>(
        `${API_URL}/movies/${movieId.toString()}`,
        {
            params: {
                token: getUserId(),
                lang: getLang()
            }
        }
    );
};

export const getPlayback = async (movieId: number): Promise<PlaybackModel> => {
    return await api(`${API_URL}/playback`, {
        params: {
            id: movieId.toString(),
            token: await deviceToken(),
            userId: getUserId(),
            platform: await getPlatform()
        }
    });
};

export const saveMovieProgress = async (movieId: number, time: number): Promise<void> => {
    await api(`${API_URL}/movies/progress`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            movie_id: movieId,
            time: time,
            token: getUserId()
        })
    });
};

export const getFavorites = async (): Promise<CategoryModel[]> => {
    const id = getUserId();
    return await new Promise<CategoryModel[]>((resolve) => {
        let favorites = <CategoryModel>{};
        let watched = <CategoryModel>{};

        let requestsCompleted = 0;

        const normalizeArray = (id: string, items: MovieDetailsModel[]): CategoryModel => {
            return {
                categoryId: 0,
                categoryKey: id,
                movies: normalizeMovies(items || [])
            };
        };

        api<MovieDetailsModel[]>(`${API_URL}/favorites`, {
            params: {
                category: "favourites",
                token: id
            }
        })
            .then((data) => {
                favorites = normalizeArray("favorites", data);
            })
            .catch()
            .finally(() => {
                requestsCompleted++;
                if (requestsCompleted === 2) {
                    resolve([watched, favorites]);
                }
            });

        api<MovieDetailsModel[]>(`${API_URL}/movies`, {
            params: {
                category: "watched",
                token: id
            }
        })
            .then((data) => {
                watched = normalizeArray("watched", data);
            })
            .catch()
            .finally(() => {
                requestsCompleted++;
                if (requestsCompleted === 2) {
                    resolve([watched, favorites]);
                }
            });
    });
};

export const search = async (q: string, signal: AbortSignal): Promise<MovieDetailsModel[]> => {
    return (
        await api<MovieDetailsModel[]>(`${API_URL}/search`, {
            params: {
                title: q,
                lang: getLang()
            },
            signal
        })
    ).map((m: MovieDetailsModel) => ({
        backdropUrl: m.backdropUrl,
        title: m.title,
        duration: m.duration,
        productionYear: m.productionYear,
        movieId: m.movieId,
        isFavorite: m.isFavorite,
        progress: m.progress,
        coverUrl: m.coverUrl,
        isHd: m.isHd,
        is4k: m.is4k,
        description: m.description
    }));
};

export const toggleFavorite = async (movieId: number): Promise<MovieModel[]> => {
    return await api(`${API_URL}/movies/favorite`, {
        params: {
            movie_id: movieId.toString(),
            token: getUserId()
        },
        method: "POST"
    });
};

export const getSubscriptionLink = async (): Promise<{
    url: string;
    freeTrialAvailable: boolean;
    monthlyPrice: string;
}> => {
    return await api(`${API_URL}/url`, {
        params: {
            token: await deviceToken(),
            platform: await getPlatform()
        }
    });
};

export const getSubscription = async (): Promise<UserModel> => {
    return await api(`${API_URL}/account`, {
        params: { token: await deviceToken(), platform: await getPlatform(), userId: getUserId() }
    });
};

export const logout = async (): Promise<void> => {
    return await api(`${API_URL}/logout`, {
        params: { token: await deviceToken(), platform: await getPlatform() }
    });
};

export const syncExternalSubscription = async (data: GoogleIAPData): Promise<UserModel> => {
    console.log(`try sync: ${JSON.stringify(data)}`);
    return await api(`${API_URL}/external-subscription`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ...data,
            platform: await getPlatform()
        })
    });
};
