// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import { Language, Lightning, Registry, Router } from "@lightningjs/sdk";
import { MoviePosterWithLabel } from "../MoviePosterWithLabel/MoviePosterWithLabel";
import { DIMENSIONS, PAGE_WIDTH } from "../../lib/utils";
import { TranslatableText } from "../TranslatableText/TranslatableText";
import { MovieModel, TranslationModel } from "../../lib/models";
import { getMoviesByCategory } from "../../lib/api";

interface MovieListTemplateSpec extends Lightning.Component.TemplateSpec {
    items: MovieModel[];
    categoryId: number;
    categoryTranslations: TranslationModel[];
    category: string;
    categoryKey: string;
    smallerPosters: boolean;
    navigateOnSelect: boolean;
    offsetX: number;
    Category: typeof TranslatableText;
    ListContainer: {
        Placeholders: object;
        List: {
            type: typeof List;
        };
    };
}

export class MovieList
    extends Lightning.Component<MovieListTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieListTemplateSpec>
{
    _itemsLength = 0;
    _categoryTranslations!: TranslationModel[];
    _categoryId!: number;
    _category!: string;
    _categoryKey!: string;

    _smallerPosters = false;
    _navigateOnSelect = true;

    static override _template(): Lightning.Component.Template<MovieListTemplateSpec> {
        return {
            alpha: 0.01,
            collision: true,
            Category: {
                x: DIMENSIONS.pageGap,
                type: TranslatableText,
                text: {
                    fontSize: 40,
                    fontFace: "Secondary-Regular"
                }
            },
            ListContainer: {
                collision: true,
                x: DIMENSIONS.pageGap,
                y: 16,
                Placeholders: {
                    alpha: 0.01,
                    y: 50,
                    w: PAGE_WIDTH - 6,
                    flex: {
                        direction: "row"
                    }
                },
                List: {
                    alpha: 0,
                    collision: true,
                    // @ts-expect-error no types available yet
                    type: List,
                    direction: "row",
                    spacing: 30,
                    w: PAGE_WIDTH - 6,
                    enableRequests: true,
                    requestThreshold: 10,
                    signals: { onRequestItems: true },
                    gcThreshold: 15,
                    scroll: {
                        after: 5
                    },
                    scrollTransition: {
                        duration: 0
                    }
                }
            }
        };
    }
    readonly Category = this.getByRef("Category")!;
    readonly ListContainer = this.getByRef("ListContainer")!;
    readonly Placeholders = this.ListContainer.getByRef("Placeholders")!;
    readonly List = this.ListContainer.getByRef("List")!;

    override _getFocused(): Lightning.Component | null | undefined {
        // @ts-expect-error no types available yet
        return this.List;
    }

    override _setup() {
        this.Placeholders.children = Array(7).fill({
            ...this._getMoviePosterProps(),
            flexItem: {
                marginRight: 30
            }
        });
    }

    override _active() {
        this.fadeIn();

        this.onRequestItems();
    }

    override _handleBack() {
        // @ts-expect-error no types available yet
        if (this.List.index > 0) {
            // @ts-expect-error no types available yet
            this.List.index = 0;
            return true;
        }

        return false;
    }

    _setItems(data: MovieModel[]) {
        this._itemsLength = data.length;

        if (data.length) {
            this.List.patch({
                // @ts-expect-error no types available yet
                scrollTransition: {
                    duration: 0
                }
            });

            if (!data[0]?.movieId) {
                // list items
                this.List.add(
                    data.map((item: any) => {
                        return {
                            ...this._getMoviePosterProps(),
                            data: item.data
                        };
                    })
                );
            } else {
                // array of model data
                // @ts-expect-error no types available yet
                this.List.reload(
                    data.map((item) => {
                        return {
                            ...this._getMoviePosterProps(),
                            data: item
                        };
                    })
                );

                this._enableAnimation();
            }

            for (const wrapper of this.List.children) {
                wrapper?.patch({
                    collision: true
                });
            }

            this.List.patch({
                smooth: {
                    alpha: 1
                }
            });

            this.Placeholders.patch({
                smooth: {
                    alpha: 0.01
                }
            });
        } else {
            // @ts-expect-error no types available yet
            this.List.clear();
        }
    }

    _update() {
        this.Placeholders.patch({
            // @ts-expect-error no types available yet
            alpha: this.List.items.length ? 0.01 : 1
        });

        if (this._categoryTranslations) {
            this.Category.patch({
                text: {
                    text:
                        this._categoryTranslations.find((tr) => tr.language === Language.get())?.name ||
                        this._categoryTranslations.find((tr) => tr.language === "en")?.name
                }
            });
        } else if (this._category) {
            this.Category.patch({
                text: {
                    text: this._category
                }
            });
        } else {
            this.Category.patch({
                key: this._categoryKey
            });
        }

        this.List.patch({ y: 50 });
    }

    _getMoviePosterProps() {
        return {
            type: MoviePosterWithLabel,
            w: this._smallerPosters ? 200 : 240,
            h: 438,
            posterHeight: this._smallerPosters ? 296 : 356
        };
    }

    _enableAnimation() {
        Registry.setTimeout(() => {
            this.List.patch({
                // @ts-expect-error no types available yet
                scrollTransition: {
                    duration: 0.4,
                    timingFunction: "linear"
                }
            });
        }, 400);
    }

    async onRequestItems() {
        // @ts-expect-error no types available yet
        if (this._categoryId && this._categoryId > 0 && !this.List.items.length) {
            const data = await getMoviesByCategory(this._categoryId);

            this.fireAncestors("$categoryMoviesLoaded", { id: this._categoryId, movies: data });

            this._setItems(data);
        }
    }

    fadeIn() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    fadeOut() {
        this.patch({
            smooth: {
                alpha: 0.01
            }
        });
    }

    clear() {
        // @ts-expect-error no types available yet
        this.List.clear();
    }

    $movieClicked(movie: MovieModel) {
        // @ts-expect-error no types available yet
        const listIndex = this.List.items.findIndex((item: any) => {
            return item?.data?.movieId === movie.movieId;
        });

        // @ts-expect-error no types available yet
        if (listIndex > -1 && listIndex !== this.List.index) {
            // @ts-expect-error no types available yet
            this.List.setIndex(listIndex);
        }

        if (!this.hasFocus()) {
            // @ts-expect-error no types available yet,
            this.fireAncestors("$categorySelected", this.parent.componentIndex);
        }
    }

    $movieSelected(movie: MovieModel) {
        if (this._navigateOnSelect) {
            Router.navigate(`movie/${movie.movieId}`, {
                keepAlive: true
            });
        } else {
            this.fireAncestors("$movieSelected", movie);
        }
    }

    static get height() {
        return 560;
    }

    static get width() {
        return PAGE_WIDTH;
    }

    get index() {
        // @ts-expect-error no types available yet
        return this.List.index;
    }

    set index(value: number) {
        // @ts-expect-error no types available yet
        this.List.setIndex(value);
    }

    set offsetX(value: number) {
        this.Category.patch({ x: value });
        this.ListContainer.patch({ x: value });
    }

    set smallerPosters(value: boolean) {
        this._smallerPosters = value;
    }

    set navigateOnSelect(value: boolean) {
        this._navigateOnSelect = value;
    }

    set categoryId(value: number) {
        this._categoryId = value;
    }

    get categoryId() {
        return this._categoryId;
    }

    set items(value: MovieModel[]) {
        this._setItems(value);
    }

    get items() {
        // @ts-expect-error no types available yet
        return this.List.items;
    }

    set category(value: string) {
        this._category = value;

        this._update();
    }

    set categoryKey(value: string) {
        this._categoryKey = value;

        this._update();
    }

    get categoryKey() {
        return this._categoryKey;
    }

    set categoryTranslations(value: TranslationModel[]) {
        this._categoryTranslations = value;

        this._update();
    }

    get lastIndex() {
        // @ts-expect-error no types available yet
        return this.List.index;
    }

    set lastIndex(value: number) {
        // @ts-expect-error no types available yet
        if (this.List.items.length) {
            // @ts-expect-error no types available yet
            this.List.index = value;

            this._enableAnimation();
        }
    }
}
