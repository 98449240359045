import { Colors, Lightning, Registry, Utils } from "@lightningjs/sdk";
import { DEVICE_DIMENSIONS } from "../../lib/utils";
import { MovieModel } from "../../lib/models";

interface PageBackgroundTemplateSpec extends Lightning.Component.TemplateSpec {
    Background: {
        Placeholder: object;
        MovieBackdrop: {
            Backdrops: {
                Backdrop: object;
                BackdropHelper: object;
            };
            GradientLeft: object;
            GradientBottom: object;
        };
    };
}

export class PageBackground
    extends Lightning.Component<PageBackgroundTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PageBackgroundTemplateSpec>
{
    _timeout: undefined | number;
    _lastSrc: undefined | string;
    _index = 0;

    _boundEventHandlers: any = {};

    readonly Background = this.getByRef("Background")!;
    readonly MovieBackdrop = this.Background.getByRef("MovieBackdrop")!;

    readonly Backdrops = this.MovieBackdrop.getByRef("Backdrops")!;
    readonly Backdrop = this.Backdrops.getByRef("Backdrop")!;
    readonly BackdropHelper = this.Backdrops.getByRef("BackdropHelper")!;

    static override _template(): Lightning.Component.Template<PageBackgroundTemplateSpec> {
        return {
            Background: {
                alpha: 1,
                w: DEVICE_DIMENSIONS.w,
                h: DEVICE_DIMENSIONS.h,
                rect: true,
                color: 0xff000000,
                Placeholder: {
                    w: (w) => w,
                    h: (h) => h,
                    alpha: 1,
                    src: Utils.asset("images/bg-placeholder.png")
                },
                MovieBackdrop: {
                    w: (w) => w,
                    h: (h) => h,
                    Backdrops: {
                        w: (w) => w,
                        h: (h) => h,
                        Backdrop: {
                            w: (w) => w,
                            h: (h) => h,
                            alpha: 0.01
                            // src: Utils.asset("images/background.png")
                        },
                        BackdropHelper: {
                            w: (w) => w,
                            h: (h) => h,
                            alpha: 0.01
                            // src: Utils.asset("images/background.png")
                        }
                    },
                    GradientLeft: {
                        x: 0,
                        y: 0,
                        w: (w) => w,
                        h: (h) => h,
                        rect: true,
                        colorRight: Colors("0xff000000").alpha(0.2).get(),
                        colorLeft: Colors("0xff000000").alpha(0.9).get()
                    },
                    GradientBottom: {
                        x: 0,
                        y: (h) => h / 2.5,
                        w: (w) => w,
                        h: (h) => h / 1.5,
                        rect: true,
                        colorTop: Colors("0xff000000").alpha(0).get(),
                        colorBottom: Colors("0xff000000").alpha(0.8).get()
                    }
                }
            }
        };
    }

    override _setup() {
        this._boundEventHandlers = {
            updateBackground: this._updateBackground.bind(this),
            hideBackground: this._hideBackground.bind(this),
            showBackground: this._showBackground.bind(this),
            clearBackground: this._clearBackground.bind(this)
        };
    }

    override _attach() {
        this.application.on("updateSelectedMovie", this._boundEventHandlers.updateBackground);
        this.application.on("hideBackground", this._boundEventHandlers.hideBackground);
        this.application.on("showBackground", this._boundEventHandlers.showBackground);
        this.application.on("clearBackground", this._boundEventHandlers.clearBackground);
    }

    override _detach() {
        this.application.off("updateSelectedMovie", this._boundEventHandlers.updateBackground);
        this.application.off("hideBackground", this._boundEventHandlers.hideBackground);
        this.application.off("showBackground", this._boundEventHandlers.showBackground);
        this.application.off("clearBackground", this._boundEventHandlers.clearBackground);
    }

    override _init() {
        this.Backdrop.on("txLoaded", () => {
            this.Backdrop.setSmooth("alpha", 1);
            this.BackdropHelper.setSmooth("alpha", 0);
        });
        this.BackdropHelper.on("txLoaded", () => {
            this.BackdropHelper.setSmooth("alpha", 1);
            this.Backdrop.setSmooth("alpha", 0);
        });
    }

    _clearBackground() {
        this._lastSrc = undefined;
        this.MovieBackdrop.patch({
            alpha: 0
        });
        this.Backdrop.patch({
            src: undefined,
            alpha: 0.01
        });
        this.BackdropHelper.patch({
            src: undefined,
            alpha: 0.01
        });
    }

    _hideBackground() {
        this.Background.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    _showBackground() {
        this.MovieBackdrop.patch({
            alpha: 1
        });
        this.Background.patch({
            alpha: 1
        });
    }

    _updateBackground(movie: MovieModel) {
        this.MovieBackdrop.patch({
            alpha: 1
        });

        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = undefined;
        }

        if (movie.backdropUrl === this._lastSrc) return;

        this._timeout = Registry.setTimeout(() => {
            this._timeout = undefined;

            this._lastSrc = movie.backdropUrl;

            this.Backdrops.children[this._index]?.patch({
                src: `${this._lastSrc}?width=1920`,
                alpha: 0.001
            });
            this._index ^= 1;
        }, 350);
    }
}
