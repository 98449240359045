import { Colors, Lightning, Registry, Router } from "@lightningjs/sdk";
import { DEVICE_DIMENSIONS, DIMENSIONS, PAGES } from "../lib/utils";
// @ts-expect-error no types available yet
import { Keyboard, InputField } from "@lightningjs/ui";
import { KeyboardKey } from "../components/KeyboardKey/KeyboardKey";
import { MovieList } from "../components/MovieList/MovieList";
import { SearchSelectedMovie } from "../components/SearchSelectedMovie/SearchSelectedMovie";
import { search } from "../lib/api";
import { MovieDetailsModel } from "../lib/models";
import { LoadingCircle } from "../components/LoadingCircle/LoadingCircle";
import { TranslatableText } from "../components/TranslatableText/TranslatableText";

interface SearchTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        InputContainer: {
            Input: typeof InputField;
            Border: object;
        };
        Keyboard: typeof Keyboard;
        List: typeof MovieList;
        SelectedMovie: typeof SearchSelectedMovie;
        Loading: typeof LoadingCircle;
        NotFound: {
            Text: typeof TranslatableText;
        };
    };
}

interface SearchTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

const keyboardConfig = {
    layouts: {
        ABC: [
            ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-"],
            ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k"],
            ["l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v"],
            ["w", "x", "y", "z", "Space", "Backspace"]
        ]
    },
    buttonTypes: {
        default: {
            type: KeyboardKey,
            label: {
                text: {
                    fontSize: 32
                }
            }
        },
        Space: {
            type: KeyboardKey,
            w: 316
        },
        Backspace: {
            type: KeyboardKey,
            w: 124,
            icon: "backspace"
        }
    },
    styling: {
        align: "center", //aligns the rows when the width of the Keyboard component is bigger than 0 (zero).
        horizontalSpacing: 4, //spacing between the keys
        verticalSpacing: 4 //spacing between the rows
    }
};

export class Search
    extends Lightning.Component<SearchTemplateSpec, SearchTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SearchTemplateSpec>
{
    _selectedMovie: undefined | MovieDetailsModel;
    _timeout: undefined | number;
    _abortController!: AbortController;
    _isSearchState = false;
    _isSearchLoading = false;

    _listIndex? = 0;

    static override _template(): Lightning.Component.Template<SearchTemplateSpec> {
        return {
            collision: true,
            Page: {
                collision: true,
                x: DIMENSIONS.menu,
                InputContainer: {
                    y: 138,
                    x: 128,
                    w: 728,
                    h: 70,
                    clipping: true,
                    Input: {
                        w: 726,
                        type: InputField,
                        inputText: {
                            fontSize: 40
                        },
                        cursor: {
                            h: 60,
                            w: 2
                        },
                        maxLabelWidth: 726,
                        labelPositionStatic: false
                    },
                    Border: {
                        rect: true,
                        w: 728,
                        h: 2,
                        y: 60,
                        color: Colors("white").alpha(0.4).get()
                    }
                },
                Keyboard: {
                    collision: true,
                    y: 230,
                    x: 128,
                    type: Keyboard,
                    config: keyboardConfig,
                    currentLayout: "ABC",
                    maxCharacters: 35,
                    signals: { onInputChanged: true, onSpace: true }
                },
                List: {
                    type: MovieList,
                    y: 514,
                    navigateOnSelect: false
                },
                SelectedMovie: {
                    type: SearchSelectedMovie,
                    alpha: 0
                },
                Loading: {
                    type: LoadingCircle,
                    alpha: 0,
                    y: 514,
                    w: DEVICE_DIMENSIONS.w,
                    h: DEVICE_DIMENSIONS.h - 514
                },
                NotFound: {
                    alpha: 0,
                    y: 514,
                    w: DEVICE_DIMENSIONS.w,
                    h: DEVICE_DIMENSIONS.h - 514,
                    Text: {
                        mount: 0.5,
                        x: (w) => w / 2,
                        y: (h) => h / 2,
                        type: TranslatableText,
                        key: "noSearchResults",
                        text: {
                            textAlign: "center"
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly List = this.Page.getByRef("List")!;
    readonly InputContainer = this.Page.getByRef("InputContainer")!;
    readonly Input = this.InputContainer.getByRef("Input")!;
    readonly Keyboard = this.Page.getByRef("Keyboard")!;
    readonly SelectedMovie = this.Page.getByRef("SelectedMovie")!;
    readonly Loading = this.Page.getByRef("Loading")!;
    readonly NotFound = this.Page.getByRef("NotFound")!;

    override _setup() {
        this._abortController = new AbortController();

        this.Keyboard.inputField(this.Input);

        this._setState("SearchState");
    }

    override historyState(params?: { index: number }) {
        if (params?.index) {
            this._listIndex = params.index;
        } else {
            return {
                index: this._listIndex
            };
        }
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Keyboard;
    }

    override _active() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.search.name);

        for (const item of this.Keyboard.children) {
            item.patch({
                collision: true
            });

            for (const subItem of item.children) {
                subItem.patch({
                    collision: true
                });
            }
        }
    }

    override _inactive() {
        this._cancelSearch();
        // this._finishSearch();

        // this.List.clear();

        this.stage.gc();
    }

    _cancelSearch() {
        if (this._timeout) {
            if (this._isSearchLoading) {
                this._abortController.abort();

                this._abortController = new AbortController();
            }

            Registry.clearTimeout(this._timeout);
            this._timeout = undefined;
        }
    }

    _finishSearch(data: undefined | MovieDetailsModel[] = undefined) {
        this._isSearchState = false;

        if (data) {
            this.List.patch({ items: data });

            if (this._listIndex) {
                this.List.index = this._listIndex;
                this._listIndex = undefined;
            }
        }

        this._toggleLoader();
    }

    _toggleLoader() {
        if (this._isSearchState) {
            this._setState("SearchState");
        }

        this.Loading.patch({
            smooth: {
                alpha: this._isSearchState ? 1 : 0
            }
        });

        const listHasItems = this.List.items.length > 0;
        this.List.patch({
            smooth: {
                alpha: this._isSearchState || !listHasItems ? 0 : 1
            }
        });

        this.NotFound.patch({
            smooth: {
                alpha: this._isSearchState || listHasItems ? 0 : 1
            }
        });
    }

    onInputChanged({ input }: { input: string }) {
        this._cancelSearch();

        this._timeout = Registry.setTimeout(() => {
            this._timeout = undefined;
            this._isSearchState = true;
            this._toggleLoader();

            (async () => {
                this._isSearchLoading = true;

                if (!input.length) {
                    this.NotFound.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                    this._finishSearch();
                } else {
                    try {
                        const data = await search(input, this._abortController.signal);
                        this._finishSearch(data);
                    } catch (e: any) {
                        if (!e.aborted) {
                            this._finishSearch();
                        }
                    }
                }

                this._isSearchLoading = false;
            })();
        }, 560);
    }

    $categorySelected() {
        this._setState("ResultsState");
    }

    $focusKey(key: string) {
        this.Keyboard.focus(key);
        this._setState("SearchState");

        window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 13, code: "13" }));
    }

    static override _states() {
        return [
            class SearchState extends this {
                override _handleDown() {
                    if (this.List.items.length > 0 && !this._isSearchLoading) {
                        this._setState("ResultsState");
                    }
                }
            },
            class ResultsState extends this {
                override _getFocused() {
                    return this.List;
                }

                override _handleUp() {
                    this._setState("SearchState");
                }

                $movieSelected(data: MovieDetailsModel) {
                    this._listIndex = this.List.index;
                    this._selectedMovie = data;
                    this._setState("ResultSingleState");
                }
            },
            class ResultSingleState extends this {
                override _getFocused() {
                    return this.SelectedMovie;
                }

                override $enter() {
                    this.SelectedMovie.patch({ smooth: { alpha: 1 }, data: this._selectedMovie });
                    this.List.patch({ smooth: { alpha: 0 } });
                }

                override $exit() {
                    this.List.patch({ smooth: { alpha: 1 } });
                    this.SelectedMovie.patch({ smooth: { alpha: 0 } });
                }

                override _handleUp() {
                    this._setState("SearchState");
                }

                override _handleBack() {
                    if (!this.List.items.length) this.onInputChanged({ input: this.Input.input });

                    this._setState("ResultsState");
                    return true;
                }
            }
        ];
    }

    override _handleBack() {
        if (!Router.getHistory().length) return true;

        Router.go(Router.getHistory().length * -1);

        return true;
    }
}
