import { Language, Lightning, Storage } from "@lightningjs/sdk";
import { SettingsItem } from "./SettingsItem";
import { STORAGE_KEYS } from "../../lib/utils";

interface SettingsLanguageTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItem;
}

export class SettingsLanguage
    extends Lightning.Component<SettingsLanguageTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsLanguageTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsLanguageTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Layout: {
                type: SettingsItem,
                title: "language"
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _setup() {
        const options = Language.available();

        const selected = options.find((option: { code: string; name: string }) => option.code === Language.get());

        this.SettingsItem.patch({
            options: options,
            valueKey: "code",
            labelKey: "name",
            selectedOption: selected.name
        });
    }

    $optionSelected(code: string) {
        const selected = Language.available().find((lang: any) => lang.code === code);

        this.SettingsItem.patch({
            selectedOption: selected.name
        });

        code = selected?.code || "en";
        Language.set(code)
            .then(() => {
                this.application.emit("appLanguageChanged");
                Storage.set(STORAGE_KEYS.selectedLanguage, code);
            })
            .catch();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
        this.SettingsItem.focusList();
    }
}
