import { Colors, Lightning, Registry, Router } from "@lightningjs/sdk";
import { DEVICE_DIMENSIONS } from "../../lib/utils";
import { TranslatableText } from "../../components/TranslatableText/TranslatableText";
import { LoadingCircle } from "../../components/LoadingCircle/LoadingCircle";

interface IAPSubscriptionTemplateSpec extends Lightning.Component.TemplateSpec {
    Loading: typeof LoadingCircle;
    Box: {
        Background: object;
        Content: {
            Error: typeof TranslatableText;
        };
    };
}

export class IAPSubscription
    extends Lightning.Component<IAPSubscriptionTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPSubscriptionTemplateSpec>
{
    _activePage: undefined | string = "home";
    _items: any[] = [];

    _loginTimeout: undefined | number;

    _boundEventHandlers: any = {};

    _loading = false;

    readonly Loading = this.getByRef("Loading")!;
    readonly Error = this.getByRef("Box")!;

    static override _template(): Lightning.Component.Template<IAPSubscriptionTemplateSpec> {
        return {
            w: DEVICE_DIMENSIONS.w,
            h: DEVICE_DIMENSIONS.h,
            x: 0,
            y: 0,
            rect: true,
            color: Colors("0xff000000").alpha(0.6).get(),
            alpha: 0,
            zIndex: 40,
            collision: true,
            Loading: {
                w: DEVICE_DIMENSIONS.w,
                h: DEVICE_DIMENSIONS.h,
                type: LoadingCircle,
                alpha: 1
            },
            Box: {
                w: 560,
                h: 300,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                alpha: 0,
                Background: {
                    x: -1,
                    y: -1,
                    texture: Lightning.Tools.getRoundRect(
                        560,
                        300,
                        12,
                        1,
                        Colors("white").alpha(0.2).get(),
                        true,
                        Colors("background").alpha(0.9).get()
                    )
                },
                Content: {
                    w: (w) => w,
                    h: (h) => h,
                    flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                    Error: {
                        flexItem: { marginBottom: 12 },
                        type: TranslatableText,
                        key: "externalSubscriptionError",
                        text: {
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 40
                        }
                    }
                }
            }
        };
    }

    override _getFocused() {
        return this;
    }

    override _setup() {
        this._boundEventHandlers = {
            _toggleLoader: this._toggleLoader.bind(this)
        };
    }

    override _attach() {
        Registry.addEventListener(window, "externalSubscriptionSync", this._boundEventHandlers._toggleLoader);
    }

    override _detach() {
        Registry.addEventListener(window, "externalSubscriptionSync", this._boundEventHandlers._toggleLoader);
    }

    _toggleLoader({ detail }: any) {
        const { syncing, error } = detail;

        this._loading = syncing;

        this.Loading.patch({
            alpha: syncing ? 1 : 0
        });

        this.Error.patch({
            alpha: error ? 1 : 0
        });

        if (syncing || error) {
            Router.focusWidget("IAPSubscription");
        } else {
            Router.focusPage();
        }
    }

    override _focus() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleBack() {
        if (!this._loading) {
            Router.focusPage();
        }
    }

    override _handleDown() {
        //
    }

    override async _active() {
        // this.Error.patch({
        //     alpha: 0
        // });
    }

    override async _inactive() {
        this.Error.patch({
            alpha: 0
        });
    }
}
