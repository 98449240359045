import { Lightning } from "@lightningjs/sdk";
import { MovieDetails } from "../MovieDetails/MovieDetails";
import { MovieActions } from "../MovieActions/MovieActions";
import { MovieDetailsModel } from "../../lib/models";
import { MoviePoster } from "../MoviePoster/MoviePoster";

interface SearchSelectedMovieTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MovieDetailsModel;
    Info: {
        MovieDetails: typeof MovieDetails;
        Description: object;
        MovieActions: typeof MovieActions;
    };
    Cover: typeof MoviePoster;
}

export class SearchSelectedMovie
    extends Lightning.Component<SearchSelectedMovieTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SearchSelectedMovieTemplateSpec>
{
    readonly Info = this.getByRef("Info")!;
    readonly MovieDetails = this.Info.getByRef("MovieDetails")!;
    readonly Description = this.Info.getByRef("Description")!;
    readonly MovieActions = this.Info.getByRef("MovieActions")!;
    readonly Cover = this.getByRef("Cover")!;
    readonly Details = this.Info.getByRef("MovieDetails")!;
    readonly Buttons = this.Info.getByRef("MovieActions")!;

    static override _template(): Lightning.Component.Template<SearchSelectedMovieTemplateSpec> {
        return {
            Info: {
                y: 508,
                x: 128,
                flex: { direction: "column" },
                MovieDetails: {
                    maxW: 800,
                    type: MovieDetails
                },
                Description: {
                    w: 700,
                    flexItem: { marginTop: 8 },
                    text: {
                        text: "",
                        fontSize: 24,
                        lineHeight: 29,
                        textOverflow: "ellipsis",
                        wordWrap: true,
                        maxLines: 2
                    }
                },
                MovieActions: {
                    type: MovieActions,
                    y: 25
                }
            },
            Cover: {
                y: 136,
                x: 1078,
                w: 492,
                h: 728,
                type: MoviePoster
            }
        };
    }

    override _getFocused() {
        return this.MovieActions;
    }

    set data(value: MovieDetailsModel) {
        this.application.emit("updateSelectedMovie", value);

        this.Cover.patch({
            data: value
        });

        this.Description.patch({
            text: {
                text: value.description
            }
        });

        this.Details.patch({
            data: value
        });

        this.Buttons.patch({
            data: value
        });
    }
}
