import { Colors, Lightning, Router, Utils } from "@lightningjs/sdk";
import { PAGE } from "../../../lib/utils";

interface MenuItemTemplateSpec extends Lightning.Component.TemplateSpec {
    activeId: undefined | string;
    page: PAGE;
    icon: string;
    Icon: {
        Background: object;
        Active: object;
        Inactive: object;
    };
}

export class MenuItem
    extends Lightning.Component<MenuItemTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MenuItemTemplateSpec>
{
    _id = "";
    _link = "";
    _iconSize = 48;
    _activeItem: undefined | string;

    _inFocus = false;

    private static _bgSize = 60;

    static override _template(): Lightning.Component.Template<MenuItemTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            w: this._bgSize,
            h: this._bgSize,
            Icon: {
                Background: {
                    alpha: 0,
                    texture: Lightning.Tools.getRoundRect(
                        this._bgSize,
                        this._bgSize,
                        12,
                        0,
                        Colors("focus").alpha(1).get(),
                        true,
                        Colors("focus").alpha(1).get()
                    )
                },
                Inactive: { x: 6, y: 6 },
                Active: { x: 6, y: 6, alpha: 0.01 }
            }
        };
    }

    readonly Icon = this.getByRef("Icon")!;
    readonly Background = this.Icon.getByRef("Background")!;
    readonly Active = this.Icon.getByRef("Active")!;
    readonly Inactive = this.Icon.getByRef("Inactive")!;

    static get width() {
        return this._bgSize;
    }

    static get height() {
        return this._bgSize;
    }

    get pageId() {
        return this._id;
    }

    set page(value: PAGE) {
        this._id = value.name;
        this._link = value.link;
    }
    set icon(value: string) {
        this.Inactive.patch({
            texture: Lightning.Tools.getSvgTexture(Utils.asset(`icons/${value}.svg`), this._iconSize, this._iconSize)
        });

        this.Active.patch({
            texture: Lightning.Tools.getSvgTexture(
                Utils.asset(`icons/${value}-active.svg`),
                this._iconSize,
                this._iconSize
            )
        });
    }

    set activeId(id: string | undefined) {
        this._activeItem = id;
        if (this._activeItem === this._id) {
            this._onFocus();
        } else {
            if (!this.hasFocus()) {
                this._onUnfocus();
            }
        }
    }

    get activeId() {
        return this._activeItem;
    }

    override _focus() {
        this._onFocus();
    }

    override _unfocus() {
        if (this._inFocus || this._activeItem !== this._id) {
            this._onUnfocus();
        }
    }

    override _handleEnter() {
        this.application.emit("setActivePage", this._id);

        Router.navigate(this._link);
        Router.focusPage();
    }

    _handleClick() {
        this._handleEnter();
    }

    _onFocus() {
        this.Background.patch({ smooth: { alpha: this.hasFocus() ? 1 : 0.8 } });
        this.Active.patch({ smooth: { alpha: 1 } });
        this.Inactive.patch({ smooth: { alpha: 0.01 } });
    }

    _onUnfocus() {
        this.Background.patch({ smooth: { alpha: 0 } });
        this.Active.patch({ smooth: { alpha: 0.01 } });
        this.Inactive.patch({ smooth: { alpha: 1 } });
    }

    isActive() {
        return this._activeItem === this._id;
    }

    setFocus(value: boolean) {
        this._inFocus = value;
        this.setActiveId();
    }

    setActiveId(id?: string | undefined) {
        if (id) {
            this._activeItem = id;
        }
        if (!this._inFocus && this._activeItem === this._id) {
            this._onFocus();
        } else {
            if (!this.hasFocus()) {
                this._onUnfocus();
            }
        }
    }
}
