import { Colors, Lightning, Router } from "@lightningjs/sdk";
import { DEVICE_DIMENSIONS, TERMS_URL } from "../../lib/utils";
// @ts-expect-error no types available yet
import QRCode from "qrcode";
import { TranslatableText } from "../../components/TranslatableText/TranslatableText";

interface TermsTemplateSpec extends Lightning.Component.TemplateSpec {
    Box: {
        Background: object;
        Content: {
            Label: typeof TranslatableText;
            Price: typeof TranslatableText;
            QrCode: object;
            Link: object;
        };
    };
}

export class Terms
    extends Lightning.Component<TermsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<TermsTemplateSpec>
{
    _activePage: undefined | string = "home";
    _items: any[] = [];

    _loginTimeout: undefined | number;

    readonly Box = this.getByRef("Box")!;
    readonly Content = this.Box.getByRef("Content")!;
    readonly QrCode = this.Content.getByRef("QrCode")!;
    readonly Link = this.Content.getByRef("Link")!;

    static override _template(): Lightning.Component.Template<TermsTemplateSpec> {
        return {
            zIndex: 40,
            w: DEVICE_DIMENSIONS.w,
            h: DEVICE_DIMENSIONS.h,
            x: 0,
            y: 0,
            rect: true,
            color: Colors("0xff000000").alpha(0.6).get(),
            alpha: 0,
            collision: true,
            Box: {
                w: 500,
                h: 600,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                Background: {
                    x: -1,
                    y: -1,
                    texture: Lightning.Tools.getRoundRect(
                        500,
                        600,
                        12,
                        1,
                        Colors("white").alpha(0.2).get(),
                        true,
                        Colors("background").alpha(0.9).get()
                    )
                },
                Content: {
                    w: (w) => w,
                    h: (h) => h,
                    flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                    Label: {
                        flexItem: { marginBottom: 24 },
                        type: TranslatableText,
                        key: "settingTerms",
                        text: {
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 40,
                            wordWrap: true,
                            wordWrapWidth: 400
                        }
                    },
                    QrCode: { w: 360, h: 360 },
                    Link: {
                        flexItem: { marginTop: 16 },
                        text: {
                            text: "",
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 32,
                            wordWrap: true,
                            wordWrapWidth: 400
                        }
                    }
                }
            }
        };
    }

    override _getFocused() {
        return this;
    }

    override _focus() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleBack() {
        Router.focusPage();
    }

    override _handleDown() {
        //
    }

    override async _active() {
        const link = TERMS_URL;

        const qrcode = await QRCode.toDataURL(link, {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 1
        });
        this.QrCode.patch({
            src: qrcode
        });
        this.Link.patch({
            text: {
                text: link.replace("/", " /")
            }
        });
    }

    _handleClick() {
        this._handleBack();
    }
}
