import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../TranslatableText/TranslatableText";
// @ts-expect-error no types available yet
import { ProgressBar } from "@lightningjs/ui";
import { MovieModel } from "../../lib/models";

interface ProgressTemplateSpec extends Lightning.Component.TemplateSpec {
    duration: number;
    progress: number;
    Progress: {
        Label: typeof TranslatableText;
        Bar: typeof ProgressBar;
        Percent: object;
    };
}

export class Progress
    extends Lightning.Component<ProgressTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<ProgressTemplateSpec>
{
    _duration = 0;
    _progress = 0;

    _boundEventHandler?: any;

    readonly Progress = this.getByRef("Progress")!;
    readonly Bar = this.Progress.getByRef("Bar")!;
    readonly Percent = this.Progress.getByRef("Percent")!;

    static override _template(): Lightning.Component.Template<ProgressTemplateSpec> {
        return {
            Progress: {
                flex: {
                    alignItems: "center"
                },
                Label: {
                    type: TranslatableText,
                    key: "progress",
                    text: {
                        fontSize: 24
                    }
                },
                Bar: {
                    type: ProgressBar,
                    h: 6,
                    w: 197,
                    value: 0,
                    progressColor: Colors("primary").get(),
                    backgroundColor: Colors("white").get(),
                    flexItem: { marginRight: 16, marginLeft: 16 }
                },
                Percent: {
                    text: {
                        text: "0%",
                        fontSize: 24
                    }
                }
            }
        };
    }

    set duration(value: number) {
        // to seconds
        this._duration = value * 60;
        this._update();
    }

    set progress(value: number) {
        this._progress = value;
        this._update();
    }

    _update() {
        if (this._progress && this._duration) {
            const percent = Math.floor((this._progress / this._duration) * 100);

            this.Bar.patch({
                value: percent / 100
            });
            this.Percent.patch({
                text: {
                    text: `${percent}%`
                }
            });
        } else {
            this.Bar.patch({
                value: 0
            });
            this.Percent.patch({
                text: {
                    text: `${0}%`
                }
            });
        }
    }

    _updateSelectedMovie(movie: MovieModel) {
        this._duration = movie.duration * 60;
        this._progress = movie.progress;
        this._update();
    }

    override _setup() {
        this._boundEventHandler = this._updateSelectedMovie.bind(this);
    }

    override _active() {
        this.application.on("updateSelectedMovie", this._boundEventHandler);
    }

    override _inactive() {
        this.application.off("updateSelectedMovie", this._boundEventHandler);
    }
}
