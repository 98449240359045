import { Colors, Lightning, Router } from "@lightningjs/sdk";
import { TranslatableText } from "../components/TranslatableText/TranslatableText";
import { Button } from "../components/Button/Button";
import { DEVICE_DIMENSIONS } from "../lib/utils";

interface ErrorPageTemplateSpec extends Lightning.Component.TemplateSpec {
    Box: {
        Text: typeof TranslatableText;
        Button: typeof Button;
    };
}

interface ErrorTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class ErrorPage
    extends Lightning.Component<ErrorPageTemplateSpec, ErrorTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<ErrorPageTemplateSpec>
{
    static override _template(): Lightning.Component.Template<ErrorPageTemplateSpec> {
        return {
            w: DEVICE_DIMENSIONS.w,
            h: DEVICE_DIMENSIONS.h,
            Box: {
                w: 560,
                h: 300,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                texture: Lightning.Tools.getRoundRect(
                    560,
                    300,
                    12,
                    1,
                    Colors("white").alpha(0.2).get(),
                    true,
                    Colors("background").alpha(0.9).get()
                ),
                Text: {
                    type: TranslatableText,
                    key: "error",
                    text: {
                        textAlign: "center",
                        textWrap: true,
                        wrapWidth: 500
                    }
                },
                Button: {
                    flexItem: { marginTop: 24 },
                    w: 160,
                    type: Button,
                    label: "back"
                }
            }
        };
    }

    override _active() {
        this.application.emit("clearBackground");
    }

    override _getFocused() {
        return this.tag("Box.Button")!;
    }

    override _handleEnter() {
        Router.back();
    }
}
