import { Colors, Img, Lightning, Registry, Router, Utils } from "@lightningjs/sdk";
import { DEVICE_DIMENSIONS, SPLASH_TIME } from "../lib/utils";

interface BootTemplateSpec extends Lightning.Component.TemplateSpec {
    Splash: object;
}

interface BootTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Boot
    extends Lightning.Component<BootTemplateSpec, BootTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<BootTemplateSpec>
{
    static override _template(): Lightning.Component.Template<BootTemplateSpec> {
        return {
            w: DEVICE_DIMENSIONS.w,
            h: DEVICE_DIMENSIONS.h,
            rect: true,
            color: Colors("0xff000000").get(),
            Splash: {
                w: DEVICE_DIMENSIONS.w,
                h: DEVICE_DIMENSIONS.h,
                alpha: 0.01,
                texture: Img(Utils.asset("images/splash.png")).cover(DEVICE_DIMENSIONS.w, DEVICE_DIMENSIONS.h)
            }
        };
    }

    override _init() {
        const animation = this.tag("Splash")!.animation({
            duration: 0.4,
            repeat: 0,
            stopMethod: "immediate",
            actions: [{ p: "alpha", v: { 0: 0, 1: 1 } }]
        });
        animation.start();

        this.tag("Splash")!.on("txLoaded", () => {
            Registry.setTimeout(() => {
                animation.stop();
                Router.resume();
            }, SPLASH_TIME);
        });

        this.tag("Splash")!.on("txError", () => {
            Registry.setTimeout(() => {
                animation.stop();
                Router.resume();
            }, SPLASH_TIME);
        });
    }
}
