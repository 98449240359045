// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import { Lightning, Registry, Storage } from "@lightningjs/sdk";
import { MovieDetails } from "../MovieDetails/MovieDetails";
import { MovieList } from "../MovieList/MovieList";
import { DEVICE_DIMENSIONS, DIMENSIONS, getInfoFromTitle, PAGE_WIDTH, STORAGE_KEYS } from "../../lib/utils";
import { MovieCategory } from "../MovieCategory/MovieCategory";
import { CategoryModel, MovieModel } from "../../lib/models";

interface CategoryListTemplateSpec extends Lightning.Component.TemplateSpec {
    Top: {
        Category: typeof MovieCategory;
        Details: typeof MovieDetails;
    };
    Container: {
        List: {
            type: typeof List;
        };
    };
}

export class CategoryList
    extends Lightning.Component<CategoryListTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<CategoryListTemplateSpec>
{
    _categories: CategoryModel[] = [];
    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<CategoryListTemplateSpec> {
        return {
            x: 0,
            y: 50,
            flex: { direction: "column" },
            collision: true,
            zIndex: 10,
            Top: {
                h: 260,
                x: DIMENSIONS.pageGap,
                flex: { direction: "column", justifyContent: "flex-end" },
                Category: {
                    flexItem: { marginBottom: 8 },
                    type: MovieCategory
                },
                Details: {
                    w: 1400,
                    type: MovieDetails
                }
            },
            Container: {
                h: DEVICE_DIMENSIONS.h - 260 - 32,
                w: DEVICE_DIMENSIONS.w - DIMENSIONS.menu,
                x: 0,
                y: 32,
                clipping: true,
                collision: true,
                List: {
                    // @ts-expect-error no types available yet
                    type: List,
                    collision: true,
                    direction: "column",
                    spacing: 30,
                    h: 560,
                    y: 32,
                    w: PAGE_WIDTH,
                    scroll: {
                        after: 1
                    },
                    gcThreshold: 2,
                    scrollTransition: {
                        duration: 0.4,
                        timingFunction: "linear"
                    },
                    signals: { onIndexChanged: true }
                }
            }
        };
    }
    readonly Container = this.getByRef("Container")!;
    readonly List = this.Container.getByRef("List")!;
    readonly Top = this.getByRef("Top")!;
    readonly Category = this.Top.getByRef("Category")!;
    readonly Details = this.Top.getByRef("Details")!;

    override _setup() {
        this._boundEventHandler = this._updateMovieInfo.bind(this);
    }

    override _getFocused(): Lightning.Component | null | undefined {
        // @ts-expect-error no types available yet
        return this.List;
    }

    override _active() {
        this.application.on("updateSelectedMovie", this._boundEventHandler);
    }

    override _inactive() {
        this.application.off("updateSelectedMovie", this._boundEventHandler);
    }

    _updateMovieInfo(data: MovieModel) {
        this.Category.patch({
            label: getInfoFromTitle(data?.title).category
        });
    }

    reload(categories: CategoryModel[]) {
        this._categories = categories;

        // @ts-expect-error no types available yet
        this.List.reload(
            this._categories.map((category) => ({
                collision: true,
                type: MovieList,
                categoryId: category.categoryId,
                categoryTranslations: category.translations,
                items: category.movies || [],
                lastIndex: 0,
                categoryKey: category.categoryKey || "newReleases"
            }))
        );

        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    addItems(categories: CategoryModel[]) {
        this._categories = categories;

        // @ts-expect-error no types available yet
        if (this.List.items?.length) return;

        this.reload(categories);
    }

    reloadMovies(categories: CategoryModel[]) {
        // @ts-expect-error no types available yet
        this.List.items.map((item: any) => {
            const category = categories.find((cat) => cat.categoryKey === item.categoryKey)!;
            if (item.items.length !== category.movies!.length) {
                item.items = category.movies;
            }
        });
    }

    onIndexChanged(data: { previousIndex: number; index: number }) {
        // @ts-expect-error no types available yet
        if (this.List.items) {
            if (data.previousIndex < data.index) {
                // @ts-expect-error no types available yet
                if (typeof this.List?.items[data.previousIndex]?.fadeOut !== "undefined") {
                    // @ts-expect-error no types available yet
                    this.List.items[data.previousIndex].fadeOut();
                }
            } else {
                // @ts-expect-error no types available yet
                if (typeof this.List?.items[data.index - 1]?.fadeOut !== "undefined") {
                    // @ts-expect-error no types available yet
                    this.List.items[data.index - 1].fadeOut();
                }
            }

            // @ts-expect-error no types available yet
            if (this.List.items[data.index] && typeof this.List.items[data.index].fadeIn !== "undefined") {
                // @ts-expect-error no types available yet
                this.List.items[data.index].fadeIn();
            }
        }
    }

    $categorySelected(listIndex: number) {
        // @ts-expect-error no types available yet
        this.List.setIndex(listIndex);
    }

    $categoryMoviesLoaded({ id, movies }: { id: number; movies: MovieModel[] }) {
        this._categories.map((category) => {
            if (category.categoryId === id) {
                category.movies = movies;
            }

            return category;
        });
    }

    get items() {
        // @ts-expect-error no types available yet
        return this.List.items;
    }

    get index() {
        // @ts-expect-error no types available yet
        return this.List.index;
    }

    set index(value: number) {
        // @ts-expect-error no types available yet
        this.List.setIndex(value);
    }
}
