import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../TranslatableText/TranslatableText";
import { Icon } from "../Icon/Icon";

interface ButtonTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    icon: string;
    hasActive?: boolean;
    iconWidth: number;
    RoundedRectangle: object;
    Content: {
        Label: typeof TranslatableText;
        Icon: typeof Icon;
    };
}

export class Button
    extends Lightning.Component<ButtonTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<ButtonTemplateSpec>
{
    readonly Content = this.getByRef("Content")!;
    readonly Label = this.Content.getByRef("Label")!;
    readonly Icon = this.Content.getByRef("Icon")!;

    static override _template(): Lightning.Component.Template<ButtonTemplateSpec> {
        return {
            h: 56,
            collision: true,
            zIndex: 10,
            RoundedRectangle: {
                rect: true
            },
            Content: {
                h: (h) => h,
                flex: {
                    alignItems: "center",
                    justifyContent: "center"
                },
                Label: {
                    type: TranslatableText,
                    text: {
                        textAlign: "center",
                        fontSize: 24
                    },
                    alpha: 0
                },
                Icon: {
                    // w: 24,
                    h: 24,
                    type: Icon
                }
            }
        };
    }

    set hasActive(value: boolean) {
        this.Icon.patch({
            hasActive: value
        });
    }

    set label(value: string) {
        this.Label.patch({
            key: value,
            alpha: 1,
            flexItem: { marginLeft: 6, marginRight: 6, marginTop: 2 }
        });
    }

    set icon(value: string) {
        this.Icon.patch({
            icon: value,
            w: 24
        });
    }

    set iconWidth(value: number) {
        this.Icon.patch({
            w: value,
            h: value,
            iconWidth: value
        });
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Icon;
    }

    override _init() {
        this._createBackground(this.hasFocus());
        this.Content.patch({ w: this.w, h: this.h });
    }

    override _focus() {
        this._createBackground(true);
        this.Label.patch({
            text: {
                smooth: {
                    textColor: Colors("primary").get()
                }
            }
        });
    }

    override _unfocus() {
        this._createBackground(false);

        this.Label.patch({
            text: {
                smooth: {
                    textColor: Colors("text").get()
                }
            }
        });
    }

    _createBackground(hasFocus: boolean) {
        this.patch({
            RoundedRectangle: {
                x: -1,
                y: -1,
                texture: Lightning.Tools.getRoundRect(
                    this.w,
                    56,
                    56 / 2,
                    1,
                    Colors("primary").get(),
                    hasFocus,
                    Colors("primary").get()
                )
            }
        });
    }

    _handleClick() {
        this.signal("handleClick");
    }
}
