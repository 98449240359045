import { Lightning, Router } from "@lightningjs/sdk";
import { SettingsItem } from "./SettingsItem";

interface SettingsSubscriptionTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItem;
}

export class SettingsSubscription
    extends Lightning.Component<SettingsSubscriptionTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsSubscriptionTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsSubscriptionTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Layout: {
                type: SettingsItem,
                title: "subscriptionSettings",
                selectedOptionKey: "subscriptionManage"
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _handleEnter() {
        Router.focusWidget("Subscription");
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }
}
