import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { MovieModel } from "../../lib/models";

interface MoviePosterTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MovieModel;
    Background: {
        Poster: object;
        Placeholder: object;
    };
}

export class MoviePoster
    extends Lightning.Component<MoviePosterTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MoviePosterTemplateSpec>
{
    _src: string | undefined;
    _isActive = false;

    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<MoviePosterTemplateSpec> {
        return {
            Background: {
                w: (w) => w,
                h: (h) => h,
                rect: true,
                colorTop: Colors("focus").alpha(0.1).get(),
                colorBottom: Colors("focus").alpha(0.2).get(),
                shader: {
                    type: Lightning.shaders.RoundedRectangle,
                    radius: 12,
                    stroke: 1,
                    strokeColor: Colors("focus").alpha(0.4).get()
                },
                Placeholder: {
                    mount: 0.5,
                    w: 80,
                    h: 108,
                    x: (w) => w / 2,
                    y: (y) => y / 2,
                    texture: Lightning.Tools.getSvgTexture(Utils.asset("icons/placeholder.svg"), 80, 108)
                },
                Poster: {
                    w: (w) => w,
                    h: (h) => h,
                    alpha: 0.01
                    // src: Utils.asset("images/background.png")
                }
            }
        };
    }

    readonly Background = this.getByRef("Background")!;
    readonly Poster = this.Background.getByRef("Poster")!;
    readonly Placeholder = this.Background.getByRef("Placeholder")!;

    set data(value: MovieModel) {
        this._src = value?.coverUrl;

        this._update();
    }

    override _setup() {
        this._boundEventHandler = this._showPoster.bind(this);
    }

    override _active() {
        this.Poster.on("txLoaded", this._boundEventHandler);

        this._isActive = true;

        this._update();
    }

    override _inactive() {
        this.Poster.off("txLoaded", this._boundEventHandler);

        this._isActive = false;

        this._update();

        // this.stage.gc(true);
    }

    _showPoster() {
        this.Poster.setSmooth("alpha", 1);
    }

    _update() {
        if (this._isActive && this._src) {
            this.Poster.patch({
                src: `${this._src}?width=220&quality=80`
            });
        }
    }
}
