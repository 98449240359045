import { Lightning } from "@lightningjs/sdk";
import { PlayerProgress } from "./components/PlayerProgress";
import { formatTime } from "../../lib/utils";

interface PlayerBottomTemplateSpec extends Lightning.Component.TemplateSpec {
    CurrentTime: object;
    Duration: object;
    Progress: typeof PlayerProgress;
    duration: number;
    currentTime: number;
}

export class PlayerBottom
    extends Lightning.Component<PlayerBottomTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayerBottomTemplateSpec>
{
    _duration = 0;

    readonly CurrentTime = this.getByRef("CurrentTime")!;
    readonly Duration = this.getByRef("Duration")!;
    readonly Progress = this.getByRef("Progress")!;

    static override _template(): Lightning.Component.Template<PlayerBottomTemplateSpec> {
        return {
            w: 1520,
            x: 200,
            CurrentTime: {
                text: {
                    text: "",
                    fontSize: 24,
                    fontFace: "Secondary-Regular"
                }
            },
            Duration: {
                mountX: 1,
                x: (w) => w,
                w: 100,
                text: {
                    text: "",
                    fontSize: 24,
                    fontFace: "Secondary-Regular",
                    textAlign: "right"
                }
            },
            Progress: {
                w: (w: number) => w,
                y: 50,
                type: PlayerProgress
            }
        };
    }

    set duration(value: number) {
        this._duration = value;
        this.Duration.patch({
            text: {
                text: formatTime(value)
            }
        });
    }

    set currentTime(value: number) {
        this.CurrentTime.patch({
            text: {
                text: formatTime(value)
            }
        });

        if (value && this._duration) {
            this.Progress.patch({
                progress: value / this._duration
            });
        }
    }
}
