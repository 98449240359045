import { Lightning, Router } from "@lightningjs/sdk";
import { CategoryList } from "../components/CategoryList/CategoryList";
import { DEVICE_DIMENSIONS, DIMENSIONS, PAGE_WIDTH, PAGES } from "../lib/utils";
import { CategoryModel } from "../lib/models";
import { TranslatableText } from "../components/TranslatableText/TranslatableText";

interface FavoriteTemplateSpec extends Lightning.Component.TemplateSpec {
    Empty: {
        Text: typeof TranslatableText;
    };
    List: typeof CategoryList;
}

interface FavoriteTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Favorite
    extends Lightning.Component<FavoriteTemplateSpec, FavoriteTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<FavoriteTemplateSpec>
{
    readonly List = this.getByRef("List")!;
    readonly Empty = this.getByRef("Empty")!;

    static override _template(): Lightning.Component.Template<FavoriteTemplateSpec> {
        return {
            Empty: {
                alpha: 0,
                x: DIMENSIONS.menu,
                w: PAGE_WIDTH,
                h: DEVICE_DIMENSIONS.h,
                Text: {
                    w: 360,
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: (h) => h / 2,
                    type: TranslatableText,
                    key: "emptyFavorites",
                    text: {
                        textAlign: "center",
                        fontSize: 32,
                        lineHeight: 38
                    }
                }
            },
            List: {
                type: CategoryList,
                x: DIMENSIONS.menu
            }
        };
    }

    set data(value: CategoryModel[]) {
        const filtered = value.filter((item) => item.movies && item.movies.length);
        if (filtered.length === 0) {
            this.List.patch({
                alpha: 0
            });
            this.Empty.patch({
                alpha: 1
            });
        } else {
            this.Empty.patch({
                alpha: 0
            });
            this.List.patch({
                alpha: 1
            });
            if (this.List.items.length !== filtered.length) {
                this.List.reload(filtered);
            } else {
                this.List.reloadMovies(filtered);
            }
        }
    }

    override _getFocused() {
        return this.List;
    }

    override _firstActive() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.favorite.name);
    }

    override _handleBack() {
        if (!Router.getHistory().length) return true;
        Router.go(Router.getHistory().length * -1);
    }

    override _inactive() {
        this.stage.gc(true);
    }
}
