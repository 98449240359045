import { Colors, Lightning, Registry } from "@lightningjs/sdk";
import { LoadingCircle } from "../LoadingCircle/LoadingCircle";
import { DEVICE_DIMENSIONS, SPLASH_TIME } from "../../lib/utils";

interface LoadingCircleTemplateSpec extends Lightning.Component.TemplateSpec {
    Loading: typeof LoadingCircle;
    Splash: object;
    Background: object;
    isMain: boolean;
}

export class Loading
    extends Lightning.Component<LoadingCircleTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<LoadingCircleTemplateSpec>
{
    readonly Background = this.getByRef("Background")!;
    readonly Loading = this.getByRef("Loading")!;

    static override _template(): Lightning.Component.Template<LoadingCircleTemplateSpec> {
        return {
            Background: {
                x: 0,
                y: 0,
                w: DEVICE_DIMENSIONS.w,
                h: DEVICE_DIMENSIONS.h,
                rect: true,
                color: Colors("0xff000000").alpha(0.6).get(),
                alpha: 0
            },
            Loading: {
                w: DEVICE_DIMENSIONS.w,
                h: DEVICE_DIMENSIONS.h,
                type: LoadingCircle,
                alpha: 0
            }
        };
    }

    override _active() {
        Registry.setTimeout(() => {
            this.tag("Loading")!.patch({
                smooth: {
                    alpha: 1
                }
            });

            this.tag("Background")!.patch({
                smooth: {
                    alpha: 1
                }
            });
        }, SPLASH_TIME + 200);
    }

    override _inactive() {
        // disable on first load to show splash in BG
        this.tag("Loading")!.patch({
            alpha: 1
        });
        this.tag("Background")!.patch({
            alpha: 1
        });
    }

    set isMain(value: boolean) {
        //
    }
}
