import Hls from "hls.js";
import { VideoPlayer } from "@lightningjs/sdk";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VideoConfig {}

let hls: Hls | null = null;

const defaults = {
    debug: false,
    autoplay: true
};

const unload = (videoEl: HTMLVideoElement) => {
    if (hls && hls.destroy && hls.destroy instanceof Function) {
        hls.destroy();
        hls = null;
    }
    if (videoEl) {
        videoEl.removeAttribute("src");
        videoEl.load();
    }
};

const handleUnrecoverableError = (player: Hls, errorEvent: string) => {
    if (VideoPlayer._consumer) {
        VideoPlayer._consumer.fire("$videoPlayerError", errorEvent, VideoPlayer.currentTime);
        VideoPlayer._consumer.fire("$videoPlayerEvent", "Error", errorEvent, VideoPlayer.currentTime);
    }
    player.destroy();
};

const loader = (src: string, videoEl: HTMLVideoElement, config: VideoConfig = {}): Promise<void> => {
    return new Promise((resolve) => {
        unload(videoEl);

        videoEl.style.setProperty("background", "#000000");

        if (Hls.isSupported()) {
            hls = new Hls({ ...defaults, ...config });

            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                console.log("video and hls.js are now bound together !");
            });
            hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                console.log("manifest loaded, found " + data.levels.length + " quality level");

                resolve();
            });

            hls.on(Hls.Events.ERROR, function (event, data) {
                console.log("ERROR", data);
                if (hls && data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // try to recover network error
                            console.log("fatal network error encountered, try to recover");
                            hls.startLoad();
                            switch (data.details) {
                                case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                                    if (data.frag) {
                                        hls.currentLevel = data.frag.start + data.frag.duration + 0.1;
                                    } else {
                                        hls.startLoad();
                                    }
                                    break;

                                case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
                                    handleUnrecoverableError(hls, event);
                                    break;

                                default:
                                    hls.startLoad();
                                    break;
                            }

                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.log("fatal media error encountered, try to recover");

                            switch (data.details) {
                                case Hls.ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
                                    handleUnrecoverableError(hls, event);
                                    break;
                                default:
                                    hls.recoverMediaError();
                                    break;
                            }

                            break;
                        default:
                            // cannot recover
                            handleUnrecoverableError(hls, event);
                            break;
                    }
                }
            });

            hls.loadSource(src);
            // bind them together
            hls.attachMedia(videoEl);
        } else {
            videoEl.setAttribute("src", src);
            videoEl.load();
        }
    });
};

const unloader = (src: string, videoEl: HTMLVideoElement, config: VideoConfig): Promise<void> => {
    console.log("UNLOADER");
    return new Promise((resolve) => {
        unload(videoEl);
        resolve();
    });
};

export { VideoConfig, loader, unloader };
