// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import { Colors, Lightning, Router, Utils } from "@lightningjs/sdk";
import { DEVICE_DIMENSIONS, DIMENSIONS, PAGES } from "../../lib/utils";
import { MenuItem } from "./components/MenuItem";

interface MenuTemplateSpec extends Lightning.Component.TemplateSpec {
    Logo: object;
    List: typeof List;
    Settings: typeof MenuItem;
}

export class Menu
    extends Lightning.Component<MenuTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MenuTemplateSpec>
{
    _activePage: undefined | string = "home";
    _items: any[] = [];

    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<MenuTemplateSpec> {
        return {
            w: DIMENSIONS.menu,
            h: DEVICE_DIMENSIONS.h,
            rect: true,
            collision: true,
            color: Colors("primary").get(),
            Logo: {
                x: 75,
                y: 28,
                texture: Lightning.Tools.getSvgTexture(Utils.asset("icons/logo.svg"), 44, 44)
            },
            List: {
                y: 369,
                x: 75,
                collision: true,
                type: List,
                direction: "column",
                spacing: 36
            },
            Settings: {
                y: 956,
                x: 75,
                zIndex: 11,
                type: MenuItem,
                page: PAGES.settings,
                icon: "settings"
            }
        };
    }

    readonly List = this.getByRef("List")!;
    readonly Settings = this.getByRef("Settings")!;

    override _setup() {
        this._boundEventHandler = (args: any) => {
            this._setActivePage(this, args);
        };

        this._items = [
            { type: MenuItem, page: PAGES.home, icon: "home", activeId: this._activePage },
            { type: MenuItem, page: PAGES.browse, icon: "browse", activeId: this._activePage },
            { type: MenuItem, page: PAGES.favorite, icon: "favorite", activeId: this._activePage },
            { type: MenuItem, page: PAGES.search, icon: "search", activeId: this._activePage }
        ];
        this.List.patch({
            items: this._items
        });

        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }

        this.Settings.setActiveId(this._activePage);
    }

    override _getFocused() {
        return this.List;
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleRight() {
        Router.focusPage();
    }

    override _handleDown() {
        this._setState("SettingsState");
    }

    override _attach() {
        this.application.on("setActivePage", this._boundEventHandler);
    }

    override _detach() {
        this.application.off("setActivePage", this._boundEventHandler);
    }

    override _focus() {
        this._updateList();

        try {
            const index = this.List.items.findIndex((i: any) => i.isActive && i.isActive());

            if (index === -1) {
                this._setState("SettingsState");
                this.List.setIndex(this._items.length - 1);
            } else {
                this.List.setIndex(index);
            }
        } catch (e) {
            console.log(e);
        }
    }

    override _unfocus() {
        this._updateList();
    }

    _setActivePage(self: any = this, id: string) {
        self._updateList(id);
    }

    _updateList(activePage?: string) {
        for (const item of this.List.items) {
            item.setFocus && item.setFocus(this.hasFocus());
            if (typeof item.setActiveId !== "undefined") {
                item.setActiveId(activePage);
            }
        }

        this.Settings.setActiveId(activePage);
        this.Settings.setFocus(this.hasFocus());
    }

    static override _states() {
        return [
            class MenuState extends this {},
            class SettingsState extends this {
                override _getFocused() {
                    return this.Settings;
                }

                override _handleUp() {
                    this._setState("MenuState");
                }

                override $exit() {
                    this.List.setIndex(this._items.length - 1);
                }
            }
        ];
    }
}
