import { Colors, Lightning, Registry, Utils } from "@lightningjs/sdk";
import { TranslatableObject, TranslatableText } from "../TranslatableText/TranslatableText";

// @ts-expect-error no types available yet
import { Carousel } from "@lightningjs/ui";
import { SettingsListItem } from "./SettingsListItem";

interface SettingsItemTemplateSpec extends Lightning.Component.TemplateSpec {
    title: string | TranslatableObject;
    note: string;

    selectedOption: string;
    selectedOptionKey: string;
    options: any[];
    valueKey: string;
    labelKey: string;

    Content: {
        Label: typeof TranslatableText;
        Row: {
            Selected: typeof TranslatableText;
            Icon: object;
        };
        Note: object;
    };
    ListContainer: {
        List: typeof Carousel;
    };
}

export class SettingsItem
    extends Lightning.Component<SettingsItemTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsItemTemplateSpec>
{
    _labelKey = "label";
    _valueKey = "value";
    _options: any[] = [];
    _selectedOption: string | undefined;
    _focusList = false;

    readonly Content = this.getByRef("Content")!;
    readonly Label = this.Content.getByRef("Label")!;
    readonly Row = this.Content.getByRef("Row")!;
    readonly Icon = this.Row.getByRef("Icon")!;
    readonly Note = this.Content.getByRef("Note")!;

    readonly Selected = this.Row.getByRef("Selected")!;
    readonly ListContainer = this.getByRef("ListContainer")!;
    readonly List = this.ListContainer.getByRef("List")!;

    static override _template(): Lightning.Component.Template<SettingsItemTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Content: {
                collision: true,
                zIndex: 10,
                flex: { direction: "column", alignItems: "flex-start", justifyContent: "flex-start" },
                Label: {
                    type: TranslatableText,
                    text: {
                        fontSize: 24,
                        textColor: Colors("white").alpha(0.4).get(),
                        fontFace: "Secondary-Regular"
                    }
                },
                Row: {
                    w: 380,
                    flex: { justifyContent: "space-between", alignItems: "center" },
                    Selected: {
                        type: TranslatableText,
                        text: {
                            fontSize: 32,
                            fontFace: "SemiBold",
                            textColor: Colors("white").alpha(0.4).get()
                        }
                    },
                    Icon: {
                        alpha: 0,
                        w: 15,
                        h: 27,
                        texture: Lightning.Tools.getSvgTexture(Utils.asset("icons/chevron-right.svg"), 15, 27)
                    }
                },
                Note: {
                    text: {
                        fontSize: 20,
                        textColor: Colors("white").alpha(0.4).get(),
                        fontFace: "Secondary-Regular"
                    }
                }
            },
            ListContainer: {
                collision: true,
                zIndex: 10,
                flexItem: { marginTop: 37 },
                h: 244,
                x: 400,
                w: 300,
                clipping: true,
                y: -61,
                alpha: 0,
                List: {
                    collision: true,
                    zIndex: 10,
                    y: 100,
                    type: Carousel,
                    direction: "column",
                    spacing: 12,
                    scroll: {
                        after: 1 //start scrolling after 3 items
                    }
                }
            }
        };
    }

    set title(value: string | TranslatableObject) {
        if (typeof value === "string") {
            this.Label.patch({
                key: value
            });
        } else {
            this.Label.patch({
                translate: value
            });
        }
    }

    set selectedOption(value: string) {
        this._selectedOption = value;
        this.Selected.patch({
            text: {
                text: value
            }
        });
    }

    set selectedOptionKey(value: string) {
        this.Selected.patch({
            key: value
        });
    }

    set options(value: any[]) {
        this._options = value;
        this._update();
    }

    set valueKey(value: string) {
        this._valueKey = value;
    }

    set labelKey(value: string) {
        this._labelKey = value;
        this._update();
    }

    set note(value: string) {
        this.Note.patch({
            text: {
                text: value
            }
        });
    }

    _update() {
        if (this._labelKey) {
            this.Icon.patch({
                alpha: 1
            });
            this.List.patch({
                items: this._options.map((item) => ({
                    type: SettingsListItem,
                    label: item[this._labelKey],
                    value: item[this._valueKey]
                }))
            });

            for (const wrapper of this.List.children) {
                wrapper?.patch({
                    collision: true
                });
            }
        }
    }

    get currentIndex() {
        return this.List.index;
    }

    override _focus() {
        this.Selected.patch({
            text: {
                textColor: Colors("white").alpha(1).get()
            }
        });

        if (this._options?.length) {
            this.ListContainer.patch({
                smooth: { alpha: 1 }
            });

            this.Icon.patch({
                alpha: 1
            });

            for (const wrapper of this.List.children) {
                for (const itemWrapper of wrapper.children) {
                    if (itemWrapper.children.length) {
                        if (itemWrapper.children[0].label === this._selectedOption) {
                            itemWrapper.children[0].focusIn();
                        }
                    }
                }
            }
        }
    }

    override _unfocus() {
        this.Selected.patch({
            text: {
                textColor: Colors("white").alpha(0.4).get()
            }
        });
        this.ListContainer.patch({
            smooth: {
                alpha: 0
            }
        });

        this.Icon.patch({
            alpha: 0.01
        });
    }

    override _handleLeft() {
        if (!this._focusList) return false;

        this._focusList = false;
    }

    override _handleRight() {
        if (!this._options?.length) return false;

        this._focusList = true;
    }

    focusList() {
        this._handleRight();
    }

    override _getFocused() {
        if (this._options?.length) {
            return this._focusList ? this.List : undefined;
        } else {
            return undefined;
        }
    }
}
