import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../TranslatableText/TranslatableText";

interface SecondaryButtonTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    RoundedRectangle: object;
    Content: {
        Label: typeof TranslatableText;
    };
}

export class SecondaryButton
    extends Lightning.Component<SecondaryButtonTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SecondaryButtonTemplateSpec>
{
    readonly Content = this.getByRef("Content")!;
    readonly Label = this.Content.getByRef("Label")!;

    static override _template(): Lightning.Component.Template<SecondaryButtonTemplateSpec> {
        return {
            h: 48,
            collision: true,
            zIndex: 20,
            RoundedRectangle: {
                rect: true
            },
            Content: {
                h: (h) => h,
                flex: {
                    alignItems: "center",
                    justifyContent: "center"
                },
                Label: {
                    flexItem: { marginLeft: 6, marginRight: 6 },
                    type: TranslatableText,
                    text: {
                        textAlign: "center",
                        fontSize: 24
                    },
                    alpha: 0
                }
            }
        };
    }

    set label(value: string) {
        this.Label.patch({
            key: value,
            alpha: 1
        });
    }

    override _init() {
        this._createBackground(this.hasFocus());
        this.Content.patch({ w: this.w, h: this.h });
    }

    override _focus() {
        this._createBackground(true);
        this.Label.patch({
            text: {
                smooth: {
                    textColor: Colors("primary").get()
                }
            }
        });
    }

    override _unfocus() {
        this._createBackground(false);

        this.Label.patch({
            text: {
                smooth: {
                    textColor: Colors("text").get()
                }
            }
        });
    }

    _createBackground(hasFocus: boolean) {
        this.patch({
            RoundedRectangle: {
                texture: Lightning.Tools.getRoundRect(
                    this.w - 2,
                    this.h - 2,
                    24,
                    0,
                    Colors("white").alpha(0.2).get(),
                    true,
                    hasFocus ? Colors("primary").get() : Colors("white").alpha(0.2).get()
                )
            }
        });
    }

    _handleClick() {
        this.signal("handleClick");
    }

    _handleHover() {
        this.signal("handleHover");
    }
}
