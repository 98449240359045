import { Language, Lightning } from "@lightningjs/sdk";

export interface TranslatableObject {
    key: string;
    value: string | object;
}
interface TranslatableTextTemplateSpec extends Lightning.Component.TemplateSpec {
    key: string;
    translate: TranslatableObject;
    text: object;
}

export class TranslatableText
    extends Lightning.Component<TranslatableTextTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<TranslatableTextTemplateSpec>
{
    _key: undefined | string;
    _obj: undefined | TranslatableObject;

    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<TranslatableTextTemplateSpec> {
        return {};
    }

    set key(value: string) {
        this._key = value;
        this._obj = undefined;

        this._translate();
    }

    set translate(obj: TranslatableObject) {
        this._obj = obj;
        this._key = undefined;

        this._translate();
    }

    override _attach() {
        this._boundEventHandler = this._translate.bind(this);
        this.application.on("appLanguageChanged", this._boundEventHandler);
    }

    override _detach() {
        this.application.off("appLanguageChanged", this._boundEventHandler);
    }

    _translate(_self: this = this) {
        if (_self._key || _self._obj) {
            this.patch({
                text: {
                    text: _self._key
                        ? Language.translate(this._key)
                        : _self._obj
                        ? Language.translate(
                              _self._obj.key,
                              typeof _self._obj.value !== "object" ? { value: _self._obj.value } : _self._obj.value
                          )
                        : ""
                }
            });
        }
    }
}
