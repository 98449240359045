// to T&C/Privacy Policy of Stingray Webpage

import { Lightning, Router } from "@lightningjs/sdk";
import { SettingsItem } from "./SettingsItem";
import { SUPPORT_EMAIL } from "../../lib/utils";

interface SettingsSupportTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItem;
}

export class SettingsSupport
    extends Lightning.Component<SettingsSupportTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsSupportTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsSupportTemplateSpec> {
        return {
            Layout: {
                type: SettingsItem,
                title: "settingsSupport",
                selectedOptionKey: SUPPORT_EMAIL
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _handleEnter() {
        Router.focusWidget("Support");
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }
}
