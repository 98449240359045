import { Colors, Lightning } from "@lightningjs/sdk";
import { MovieDetails } from "../components/MovieDetails/MovieDetails";
import { getInfoFromTitle, PAGE_OFFSET, PAGE_WIDTH } from "../lib/utils";

import { MovieCategory } from "../components/MovieCategory/MovieCategory";
import { Progress } from "../components/Progress/Progress";
import { MovieDirectors } from "../components/MovieDirectors/MovieDirectors";
import { MovieActions } from "../components/MovieActions/MovieActions";
import { TranslatableText } from "../components/TranslatableText/TranslatableText";
import { Icon } from "../components/Icon/Icon";
import { MovieDetailsModel } from "../lib/models";

interface MovieTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        Movie: {
            Top: {
                BaseInfo: {
                    Category: typeof MovieCategory;
                    Details: typeof MovieDetails;
                };
                Description: object;
                Buttons: typeof MovieActions;
            };

            Bottom: {
                Info: typeof MovieDirectors;
                Progress: typeof Progress;
            };
        };
        DetailsLabel: {
            Label: typeof TranslatableText;
            Icon: typeof Icon;
        };
    };
}

interface MovieTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Movie
    extends Lightning.Component<MovieTemplateSpec, MovieTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<MovieTemplateSpec>
{
    static override _template(): Lightning.Component.Template<MovieTemplateSpec> {
        return {
            collision: true,
            Page: {
                w: PAGE_WIDTH,
                x: PAGE_OFFSET,
                collision: true,
                Movie: {
                    collision: true,
                    Top: {
                        // collision: true,
                        y: 50,
                        flex: { direction: "column", justifyContent: "flex-end" },
                        BaseInfo: {
                            flex: { direction: "column", justifyContent: "flex-end" },
                            h: 260,
                            Category: {
                                type: MovieCategory,
                                flexItem: { marginBottom: 8 }
                            },
                            Details: {
                                type: MovieDetails
                            }
                        },
                        Description: {
                            flexItem: { marginTop: 24 },
                            w: 1200,
                            text: {
                                text: "",
                                fontSize: 28,
                                lineHeight: 32,
                                textOverflow: "ellipsis",
                                wordWrap: true,
                                maxLines: 10,
                                textColor: Colors("text").alpha(0.6).get()
                            }
                        },
                        Buttons: {
                            flexItem: { marginTop: 56 },
                            type: MovieActions
                        }
                    },
                    Bottom: {
                        y: 980,
                        Progress: {
                            type: Progress
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly Movie = this.Page.getByRef("Movie")!;
    readonly Top = this.Movie.getByRef("Top")!;
    readonly Buttons = this.Top.getByRef("Buttons")!;

    readonly Progress = this.Movie.getByRef("Bottom")!.getByRef("Progress")!;

    readonly BaseInfo = this.Top.getByRef("BaseInfo")!;
    readonly Category = this.BaseInfo.getByRef("Category")!;
    readonly Details = this.BaseInfo.getByRef("Details")!;
    readonly Description = this.Top.getByRef("Description")!;

    set data({ movie }: { movie: MovieDetailsModel }) {
        this.application.emit("clearBackground");
        this.application.emit("updateSelectedMovie", movie);

        this.Category.patch({
            label: getInfoFromTitle(movie.title).category
        });

        this.Description.patch({
            text: {
                text: movie.description || ""
            }
        });

        this.Details.patch({
            data: movie
        });

        this.Buttons.patch({
            data: movie
        });

        this.Progress.patch({
            duration: movie.duration || 0,
            progress: movie.progress || 0
        });
    }

    override _getFocused() {
        return this.Buttons;
    }
}
