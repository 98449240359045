export default function camelcaseKeys<T>(obj: T) {
    const toCamel = (str: string) => {
        return str.replace(/([-_][a-z])/gi, ($1) => {
            return $1.toUpperCase().replace("-", "").replace("_", "");
        });
    };

    const isObject = function (obj: T) {
        return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function";
    };

    const keysToCamel = function (obj: T): T | T[] {
        if (isObject(obj)) {
            const n = <T>{};

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Object.keys(obj).forEach((k) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                n[toCamel(k)] = keysToCamel(obj[k]);
            });

            return n;
        } else if (Array.isArray(obj)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return obj.map<T>((i: any) => {
                return keysToCamel(i);
            });
        }

        return obj;
    };

    return keysToCamel(obj);
}
