import { Lightning } from "@lightningjs/sdk";
import { MovieModel } from "../../lib/models";
import { MoviePoster } from "../MoviePoster/MoviePoster";
import { getInfoFromTitle } from "../../lib/utils";

interface MoviePosterWithLabelTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MovieModel;
    posterHeight: number;
    Background: typeof MoviePoster;
    Label: object;
}

const OFFSET = 30;

export class MoviePosterWithLabel
    extends Lightning.Component<MoviePosterWithLabelTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MoviePosterWithLabelTemplateSpec>
{
    _data: MovieModel | undefined;

    static override _template(): Lightning.Component.Template<MoviePosterWithLabelTemplateSpec> {
        return {
            // flex: { direction: "column" },
            collision: true,
            zIndex: 10,
            Background: {
                y: OFFSET,
                w: (w) => w,
                h: 356,
                type: MoviePoster
            },
            Label: {
                y: 392,
                w: (w) => w + 20,
                x: -10,
                // flexItem: { marginTop: 36 },
                text: {
                    text: "",
                    fontSize: 32,
                    fontFace: "Secondary-SemiBold",
                    textAlign: "center",
                    maxLines: 3,
                    textOverflow: "ellipsis"
                }
            }
        };
    }

    readonly Background = this.getByRef("Background")!;
    readonly Label = this.getByRef("Label")!;

    set data(value: MovieModel) {
        this._data = value;

        this.Label.patch({
            text: {
                text: getInfoFromTitle(this._data?.title).title
            }
        });

        this.Background.patch({
            data: this._data
        });
    }

    get data(): MovieModel {
        return this._data!;
    }

    set posterHeight(value: number) {
        this.Background.patch({
            h: value
        });
    }

    override _focus() {
        this.Background.patch({ smooth: { y: 0 } });

        if (this._data) {
            this.application.emit("updateSelectedMovie", this._data);
        }
    }

    override _unfocus() {
        this.Background.patch({ smooth: { y: OFFSET } });
    }

    override _handleEnter() {
        this._data && this.fireAncestors("$movieSelected", this._data);
    }

    _handleClick() {
        if (this.hasFocus()) {
            this._handleEnter();
        } else {
            this._data && this.fireAncestors("$movieClicked", this._data);
        }
    }
}
