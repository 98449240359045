import { Lightning } from "@lightningjs/sdk";

// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import { SettingsLanguage } from "./SettingsLanguage";
import { SettingsAuth } from "./SettingsAuth";
import { SettingsItemContainer } from "../../pages/Settings";

interface SettingsMainListTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof List;
}

interface SettingsMainListTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class SettingsMainList
    extends Lightning.Component<SettingsMainListTemplateSpec, SettingsMainListTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SettingsMainListTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingsMainListTemplateSpec> {
        return {
            collision: true,
            w: (w) => w,
            List: {
                collision: true,
                h: 200,
                w: (w: number) => w / 2,
                flexItem: { marginTop: 37 },
                x: 0,
                type: List,
                direction: "column",
                spacing: 64,
                items: [
                    { type: SettingsItemContainer, content: SettingsLanguage },
                    { type: SettingsItemContainer, content: SettingsAuth }
                ]
            }
        };
    }

    readonly List = this.getByRef("List")!;

    override _getFocused() {
        return this.List;
    }

    override _setup() {
        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    $listItemHover(e: any) {
        const index = this.List.items.findIndex((item: any) => item === e.parent);
        this.List.setIndex(index);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.signal("handleHover");

        return true;
    }
}
