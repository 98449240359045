import { Colors, Lightning } from "@lightningjs/sdk";

interface SeparatorTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    fontSize: number;
    Line: object;
    Text: object;
}

export class SeparatedText
    extends Lightning.Component<SeparatorTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SeparatorTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SeparatorTemplateSpec> {
        return {
            flex: { direction: "row", alignItems: "center" },
            Text: {
                text: { fontSize: 24, lineHeight: 24, fontFace: "Secondary-Regular" }
            },
            Line: {}
        };
    }

    set fontSize(value: number) {
        this.patch({
            Text: {
                text: {
                    fontSize: value,
                    fontFace: "Secondary-Regular"
                }
            }
        });
    }

    set label(value: string) {
        this.patch({
            Text: {
                text: value
            },
            Line: {
                flexItem: { marginLeft: 12, marginRight: 12 },
                w: 1,
                h: 18,
                y: -2,
                rect: true,
                color: Colors("focus").get()
            }
        });
    }
}
