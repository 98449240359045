import { Storage } from "@lightningjs/sdk";

import { UserModel } from "./models";

export const SPLASH_TIME = 2600;

export const DIMENSIONS = {
    menu: 168,
    pageGap: 128
};

export const DEVICE_DIMENSIONS = {
    w: 1920,
    h: 1080
};

export const PAGE_WIDTH = DEVICE_DIMENSIONS.w - DIMENSIONS.menu - DIMENSIONS.pageGap;
export const PAGE_OFFSET = DIMENSIONS.menu + DIMENSIONS.pageGap;

export const PLAYER_OFFSETS = {
    x: 200,
    top: 76,
    bottom: 900
};

export const SUBSCRIPTION_LINK = "pay.alteox.com";
export const PRIVACY_URL = "legal.stingray.com/en/privacy-policy";
export const TERMS_URL = "www.stingray.com/en/terms-and-conditions";
export const SUPPORT_EMAIL = "hello@alteox.support";

export interface PAGE {
    name: string;
    link: string;
}
export const PAGES = {
    home: {
        name: "home",
        link: "home"
    },
    browse: {
        name: "browse",
        link: "browse"
    },
    search: {
        name: "search",
        link: "search"
    },
    favorite: {
        name: "favorite",
        link: "favorite"
    },
    settings: {
        name: "settings",
        link: "settings"
    }
};

interface FormatTimeProps {
    useSeconds: boolean;
    hideEmpty: boolean;
}
export const formatTime = (
    value: number,
    { useSeconds, hideEmpty }: FormatTimeProps = { useSeconds: true, hideEmpty: false }
) => {
    const totalMinutes = Math.floor(value / 60);

    const seconds = Math.floor(value % 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);

    return hideEmpty && hours === 0 && minutes === 0 && seconds === 0
        ? ""
        : hours > 0
        ? `${hours}h ${minutes > 0 ? `${minutes}m` : ""}`
        : `${minutes}m ${useSeconds ? `${seconds}s` : ""}`;
};

export const PLATFORMS = {
    lg: "lg",
    samsung: "samsung",
    posttv: "posttv",
    metrological: "metrological",
    // zeasn: "zeasn",
    nettv: "nettv",
    whaletv: "whaletv",
    philips: "philips",
    hisense: "hisense",
    google: "google"
};

export const STORAGE_KEYS = {
    user: "user",
    platform: "platform",
    uid: "uid",
    selectedLanguage: "selectedLanguage",
    recentSearch: "recentSearch",
    lastMovieSelectedIndex: "lastMovieSelectedIndex",
    lastCategorySelectedIndex: "lastCategorySelectedIndex"
};

export const storageSaveUser = (data: UserModel | undefined) => {
    if (data) {
        Storage.set(STORAGE_KEYS.user, data);
    } else {
        Storage.remove(STORAGE_KEYS.user);
    }
};

export const getInfoFromTitle = (title: string) => {
    title = title || "";

    const separator = title.lastIndexOf("-") === -1 ? ":" : "-";

    return {
        title: title.substring(0, title.lastIndexOf(separator)) || title,
        category: title.split(separator).pop() || ""
    };
};

export const isExternalPlatform = () => {
    return !!window.Android && Storage.get(STORAGE_KEYS.platform) === PLATFORMS.google;
};

export const isSubscriptionActive = () => {
    const user = Storage.get(STORAGE_KEYS.user);
    return user && user.currentPeriodEnd && user.currentPeriodEnd > Date.now() / 1000;
};
