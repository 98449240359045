import { Colors, Lightning } from "@lightningjs/sdk";
import { Icon } from "../../Icon/Icon";

interface PlayPauseButtonTemplateSpec extends Lightning.Component.TemplateSpec {
    Background: {
        Color: object;
    };
    PauseIcon: typeof Icon;
    PlayIcon: typeof Icon;
}

export class PlayPauseButton
    extends Lightning.Component<PlayPauseButtonTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayPauseButtonTemplateSpec>
{
    _playing = false;
    static override _template(): Lightning.Component.Template<PlayPauseButtonTemplateSpec> {
        return {
            h: 120,
            w: 120,
            Background: {
                w: (w) => w,
                h: (h) => h,
                // texture: Lightning.Tools.getRoundRect(120, 120, 60, 0),
                shader: { type: Lightning.shaders.RoundedRectangle, radius: 60 },
                clipping: true,
                rect: true,
                color: Colors("white").alpha(0).get(),
                Color: {
                    w: (w) => w,
                    h: (h) => h,
                    rect: true,
                    color: Colors("white").alpha(0.2).get()
                }
            },
            PlayIcon: {
                w: 44,
                h: 48,
                type: Icon,
                icon: "play",
                hasActive: false,
                iconWidth: 44,
                iconHeight: 48,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2
            },
            PauseIcon: {
                w: 44,
                h: 48,
                type: Icon,
                icon: "pause",
                hasActive: false,
                iconWidth: 44,
                iconHeight: 48,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                alpha: 0.01
            }
        };
    }

    readonly Background = this.getByRef("Background")!;
    readonly BackgroundColor = this.Background.getByRef("Color")!;
    readonly PlayIcon = this.getByRef("PlayIcon")!;
    readonly PauseIcon = this.getByRef("PauseIcon")!;

    override _getFocused() {
        return this.PlayIcon;
    }

    override _focus() {
        this.BackgroundColor.patch({
            smooth: {
                color: Colors("primary").get()
            }
        });
    }

    override _unfocus() {
        this.BackgroundColor.patch({
            smooth: {
                color: Colors("white").alpha(0.2).get()
            }
        });
    }

    _handleHover() {
        this.signal("handleHover");
    }

    triggeredPlayPause() {
        this._playing = !this._playing;

        this._updateIcons();
    }

    triggeredPlay() {
        this._playing = true;

        this._updateIcons();
    }

    triggeredPause() {
        this._playing = false;

        this._updateIcons();
    }

    setPlayState(isPlaying: boolean) {
        this._playing = isPlaying;

        this._updateIcons();
    }

    _updateIcons() {
        this.PlayIcon.patch({
            alpha: this._playing ? 0 : 1
        });
        this.PauseIcon.patch({
            alpha: this._playing ? 1 : 0
        });
    }
}
