import { Lightning } from "@lightningjs/sdk";
import { DEVICE_DIMENSIONS } from "../../lib/utils";
import { Icon } from "../Icon/Icon";
import { PlayPauseButton } from "./components/PlayPauseButton";

interface PlayerControlsTemplateSpec extends Lightning.Component.TemplateSpec {
    Rewind: typeof Icon;
    PlayPause: typeof PlayPauseButton;
    Forward: typeof Icon;
}

export class PlayerControls
    extends Lightning.Component<PlayerControlsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayerControlsTemplateSpec>
{
    static override _template(): Lightning.Component.Template<PlayerControlsTemplateSpec> {
        return {
            w: DEVICE_DIMENSIONS.w,
            h: DEVICE_DIMENSIONS.h,
            collision: true,
            zIndex: 10,
            flex: {
                alignItems: "center",
                justifyContent: "center"
            },
            Rewind: {
                w: 60,
                h: 60,
                iconWidth: 60,
                type: Icon,
                icon: "rewind",
                signals: {
                    handleHover: "setRewindState"
                }
            },
            PlayPause: {
                type: PlayPauseButton,
                flexItem: {
                    marginLeft: 62,
                    marginRight: 62
                },
                signals: {
                    handleHover: "setPlayPauseState"
                }
            },
            Forward: {
                w: 60,
                h: 60,
                iconWidth: 60,
                type: Icon,
                icon: "forward",
                signals: {
                    handleHover: "setForwardState"
                }
            }
        };
    }

    readonly Rewind = this.getByRef("Rewind")!;
    readonly PlayPause = this.getByRef("PlayPause")!;
    readonly Forward = this.getByRef("Forward")!;

    override _active() {
        this._setState("PlayPauseState");
    }

    triggeredPlayPause() {
        this.PlayPause.triggeredPlayPause();
    }

    triggeredPause() {
        this.PlayPause.triggeredPause();
    }

    triggeredPlay() {
        this.PlayPause.triggeredPlay();
    }

    setPlayState(playing: boolean) {
        this.PlayPause.setPlayState(playing);
    }

    setRewindState() {
        this._setState("RewindState");
    }

    setPlayPauseState() {
        this._setState("PlayPauseState");
    }

    setForwardState() {
        this._setState("ForwardState");
    }

    static override _states() {
        return [
            class RewindState extends this {
                override _getFocused() {
                    return this.Rewind;
                }

                override _handleRight() {
                    this._setState("PlayPauseState");
                }

                override _handleEnter() {
                    this.fireAncestors("$triggerVideoRewind");
                }

                _handleClick() {
                    this.fireAncestors("$triggerVideoRewind");
                }

                override _handleLeft() {
                    this.fireAncestors("$triggerVideoRewind");
                }
            },
            class PlayPauseState extends this {
                override _getFocused() {
                    return this.PlayPause;
                }

                override _handleRight() {
                    this._setState("ForwardState");
                    this.fireAncestors("$triggerVideoForward");
                }

                override _handleLeft() {
                    this._setState("RewindState");
                    this.fireAncestors("$triggerVideoRewind");
                }

                override _handleEnter() {
                    this.fireAncestors("$triggerVideoPlayPause");
                }

                _handleClick() {
                    this.fireAncestors("$triggerVideoPlayPause");
                }
            },
            class ForwardState extends this {
                override _getFocused() {
                    return this.Forward;
                }

                override _handleLeft() {
                    this._setState("PlayPauseState");
                }

                override _handleEnter() {
                    this.fireAncestors("$triggerVideoForward");
                }

                _handleClick() {
                    this.fireAncestors("$triggerVideoForward");
                }

                override _handleRight() {
                    this.fireAncestors("$triggerVideoForward");
                }
            }
        ];
    }
}
