import { Lightning, Router } from "@lightningjs/sdk";

import { TranslatableText } from "../components/TranslatableText/TranslatableText";
import { DEVICE_DIMENSIONS, PAGES } from "../lib/utils";
import { SettingsInfoList } from "../components/Settings/SettingsInfoList";
import { SettingsMainList } from "../components/Settings/SettingsMainList";

interface SettingsTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        Content: {
            Title: typeof TranslatableText;
            List: typeof SettingsMainList;
            Info: typeof SettingsInfoList;
        };
    };
}

interface SettingsTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Settings
    extends Lightning.Component<SettingsTemplateSpec, SettingsTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SettingsTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingsTemplateSpec> {
        return {
            Page: {
                collision: true,
                w: DEVICE_DIMENSIONS.w,
                h: DEVICE_DIMENSIONS.h,
                flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                Content: {
                    collision: true,
                    w: 800,
                    flex: { direction: "column" },
                    Title: {
                        type: TranslatableText,
                        key: "settings",
                        text: {
                            fontSize: 32,
                            fontFace: "SemiBold"
                        }
                    },
                    List: {
                        collision: true,
                        h: 200,
                        w: (w: number) => w / 2,
                        type: SettingsMainList,
                        signals: {
                            handleHover: "_setMainState"
                        }
                    },
                    Info: {
                        collision: true,
                        type: SettingsInfoList,
                        h: 220,
                        w: (w) => w,
                        signals: {
                            handleHover: "_setInfoState"
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly Content = this.Page.getByRef("Content")!;
    readonly List = this.Content.getByRef("List")!;
    readonly Info = this.Content.getByRef("Info")!;

    override _getFocused() {
        return this.List;
    }

    override _firstActive() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.settings.name);
    }

    override _inactive() {
        this.stage.gc(true);
    }

    override _handleDown() {
        this._setInfoState();
    }

    _setInfoState() {
        this._setState("BottomState");
    }

    _setMainState() {
        this._setState("");
    }

    static override _states() {
        return [
            class BottomState extends this {
                override _getFocused() {
                    return this.Info;
                }

                override _handleUp() {
                    this._setState("");
                }
            }
        ];
    }
}

interface SettingsItemContainerTemplateSpec extends Lightning.Component.TemplateSpec {
    content: any;
    Content: any;
}

export class SettingsItemContainer
    extends Lightning.Component<SettingsItemContainerTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsItemContainerTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingsItemContainerTemplateSpec> {
        return { collision: true };
    }

    // @ts-expect-error no types available yet
    override _getFocused() {
        return this.children[0];
    }

    set content(value: any) {
        this.children = [
            {
                type: value
            }
        ];
    }

    static get width() {
        return 340;
    }

    static get height() {
        return 40;
    }

    override _handleBack() {
        if (!Router.getHistory().length) return true;
        Router.go(Router.getHistory().length * -1);
    }
}
