import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../TranslatableText/TranslatableText";

interface BadgeTemplateSpec extends Lightning.Component.TemplateSpec {
    RoundedRectangle: object;
    Label: typeof TranslatableText;
    label: string;
    isActive?: boolean;
}

export class Badge
    extends Lightning.Component<BadgeTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<BadgeTemplateSpec>
{
    readonly Label = this.getByRef("Label")!;

    _isActive = false;

    static override _template(): Lightning.Component.Template<BadgeTemplateSpec> {
        return {
            w: 80,
            h: 32,
            RoundedRectangle: {
                w: (w) => w,
                h: (h) => h,
                rect: true
            },
            Label: {
                w: (w) => w,
                type: TranslatableText,
                text: {
                    textAlign: "center",
                    fontSize: 24,
                    fontFace: "Secondary-SemiBold"
                }
            }
        };
    }

    set label(value: string) {
        this.Label.patch({
            key: value
        });
    }

    set isActive(value: boolean) {
        this._isActive = value;
    }

    override _init() {
        this._createBackground(this._isActive);
    }

    _createBackground(isActive: boolean) {
        this.patch({
            RoundedRectangle: {
                texture: Lightning.Tools.getRoundRect(
                    80,
                    32,
                    16,
                    1,
                    Colors("focus").alpha(0.6).get(),
                    true,
                    isActive ? Colors("primary").get() : Colors("focus").alpha(0.2).get()
                )
            }
        });
    }
}
